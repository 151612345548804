import { gql } from '@apollo/client';
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string | number;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** A string used to identify an i18n locale */
  I18NLocaleCode: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** A time string with format HH:mm:ss.SSS */
  Time: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  latitude?: Maybe<Scalars['Float']>;
  locations?: Maybe<LocationEntityResponseCollection>;
  longitude?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  zip?: Maybe<Scalars['String']>;
};


export type AddressLocationsArgs = {
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AddressEntity = {
  __typename?: 'AddressEntity';
  attributes?: Maybe<Address>;
  id?: Maybe<Scalars['ID']>;
};

export type AddressEntityResponse = {
  __typename?: 'AddressEntityResponse';
  data?: Maybe<AddressEntity>;
};

export type AddressEntityResponseCollection = {
  __typename?: 'AddressEntityResponseCollection';
  data: Array<AddressEntity>;
  meta: ResponseCollectionMeta;
};

export type AddressFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<AddressFiltersInput>>>;
  city?: InputMaybe<StringFilterInput>;
  country?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  latitude?: InputMaybe<FloatFilterInput>;
  longitude?: InputMaybe<FloatFilterInput>;
  not?: InputMaybe<AddressFiltersInput>;
  number?: InputMaybe<StringFilterInput>;
  or?: InputMaybe<Array<InputMaybe<AddressFiltersInput>>>;
  street?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  zip?: InputMaybe<StringFilterInput>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  number?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type Alarm = {
  __typename?: 'Alarm';
  beacons?: Maybe<BeaconRelationResponseCollection>;
  configuration: Scalars['JSON'];
  createdAt?: Maybe<Scalars['DateTime']>;
  events?: Maybe<AlarmEventRelationResponseCollection>;
  name: Scalars['String'];
  receivers?: Maybe<Scalars['JSON']>;
  resolved_message?: Maybe<Scalars['String']>;
  triggered_message?: Maybe<Scalars['String']>;
  type: Enum_Alarm_Type;
  units?: Maybe<UnitRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UsersPermissionsUserEntityResponse>;
};


export type AlarmBeaconsArgs = {
  filters?: InputMaybe<BeaconFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type AlarmEventsArgs = {
  filters?: InputMaybe<AlarmEventFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type AlarmUnitsArgs = {
  filters?: InputMaybe<UnitFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AlarmEntity = {
  __typename?: 'AlarmEntity';
  attributes?: Maybe<Alarm>;
  id?: Maybe<Scalars['ID']>;
};

export type AlarmEntityResponse = {
  __typename?: 'AlarmEntityResponse';
  data?: Maybe<AlarmEntity>;
};

export type AlarmEntityResponseCollection = {
  __typename?: 'AlarmEntityResponseCollection';
  data: Array<AlarmEntity>;
  meta: ResponseCollectionMeta;
};

export type AlarmEvent = {
  __typename?: 'AlarmEvent';
  alarm?: Maybe<AlarmEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  data?: Maybe<Scalars['JSON']>;
  item_identifier?: Maybe<Scalars['String']>;
  state: Enum_Alarmevent_State;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AlarmEventEntity = {
  __typename?: 'AlarmEventEntity';
  attributes?: Maybe<AlarmEvent>;
  id?: Maybe<Scalars['ID']>;
};

export type AlarmEventEntityResponse = {
  __typename?: 'AlarmEventEntityResponse';
  data?: Maybe<AlarmEventEntity>;
};

export type AlarmEventEntityResponseCollection = {
  __typename?: 'AlarmEventEntityResponseCollection';
  data: Array<AlarmEventEntity>;
  meta: ResponseCollectionMeta;
};

export type AlarmEventFiltersInput = {
  alarm?: InputMaybe<AlarmFiltersInput>;
  and?: InputMaybe<Array<InputMaybe<AlarmEventFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  data?: InputMaybe<JsonFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  item_identifier?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<AlarmEventFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<AlarmEventFiltersInput>>>;
  state?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type AlarmEventInput = {
  alarm?: InputMaybe<Scalars['ID']>;
  data?: InputMaybe<Scalars['JSON']>;
  item_identifier?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Enum_Alarmevent_State>;
};

export type AlarmEventRelationResponseCollection = {
  __typename?: 'AlarmEventRelationResponseCollection';
  data: Array<AlarmEventEntity>;
};

export type AlarmFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<AlarmFiltersInput>>>;
  beacons?: InputMaybe<BeaconFiltersInput>;
  configuration?: InputMaybe<JsonFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  events?: InputMaybe<AlarmEventFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<AlarmFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<AlarmFiltersInput>>>;
  receivers?: InputMaybe<JsonFilterInput>;
  resolved_message?: InputMaybe<StringFilterInput>;
  triggered_message?: InputMaybe<StringFilterInput>;
  type?: InputMaybe<StringFilterInput>;
  units?: InputMaybe<UnitFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  user?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type AlarmInput = {
  beacons?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  configuration?: InputMaybe<Scalars['JSON']>;
  events?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
  receivers?: InputMaybe<Scalars['JSON']>;
  resolved_message?: InputMaybe<Scalars['String']>;
  triggered_message?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Enum_Alarm_Type>;
  units?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  user?: InputMaybe<Scalars['ID']>;
};

export type AlarmRelationResponseCollection = {
  __typename?: 'AlarmRelationResponseCollection';
  data: Array<AlarmEntity>;
};

export type Beacon = {
  __typename?: 'Beacon';
  alarms?: Maybe<AlarmRelationResponseCollection>;
  beacon_model?: Maybe<BeaconModelEntityResponse>;
  beacon_values?: Maybe<BeaconValueRelationResponseCollection>;
  connected_units?: Maybe<UnitRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer?: Maybe<CustomerEntityResponse>;
  customer_reference_number?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  group?: Maybe<GroupEntityResponse>;
  hidden?: Maybe<Scalars['Boolean']>;
  image?: Maybe<UploadFileRelationResponseCollection>;
  indeterminate_minutes?: Maybe<Scalars['Int']>;
  last_connected?: Maybe<Scalars['DateTime']>;
  locations?: Maybe<LocationEntityResponseCollection>;
  mac_address: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  online_minutes?: Maybe<Scalars['Int']>;
  people?: Maybe<PersonRelationResponseCollection>;
  primary_image?: Maybe<Scalars['Int']>;
  registration_number?: Maybe<Scalars['String']>;
  routes?: Maybe<RouteRelationResponseCollection>;
  serial_number?: Maybe<Scalars['String']>;
  tags?: Maybe<TagRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vin?: Maybe<Scalars['String']>;
};


export type BeaconAlarmsArgs = {
  filters?: InputMaybe<AlarmFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type BeaconBeacon_ValuesArgs = {
  filters?: InputMaybe<BeaconValueFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type BeaconConnected_UnitsArgs = {
  filters?: InputMaybe<UnitFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type BeaconImageArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type BeaconLocationsArgs = {
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type BeaconPeopleArgs = {
  filters?: InputMaybe<PersonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type BeaconRoutesArgs = {
  filters?: InputMaybe<RouteFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type BeaconTagsArgs = {
  filters?: InputMaybe<TagFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BeaconEntity = {
  __typename?: 'BeaconEntity';
  attributes?: Maybe<Beacon>;
  id?: Maybe<Scalars['ID']>;
};

export type BeaconEntityResponse = {
  __typename?: 'BeaconEntityResponse';
  data?: Maybe<BeaconEntity>;
};

export type BeaconEntityResponseCollection = {
  __typename?: 'BeaconEntityResponseCollection';
  data: Array<BeaconEntity>;
  meta: ResponseCollectionMeta;
};

export type BeaconFiltersInput = {
  alarms?: InputMaybe<AlarmFiltersInput>;
  and?: InputMaybe<Array<InputMaybe<BeaconFiltersInput>>>;
  beacon_model?: InputMaybe<BeaconModelFiltersInput>;
  beacon_values?: InputMaybe<BeaconValueFiltersInput>;
  connected_units?: InputMaybe<UnitFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  customer?: InputMaybe<CustomerFiltersInput>;
  customer_reference_number?: InputMaybe<StringFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  group?: InputMaybe<GroupFiltersInput>;
  hidden?: InputMaybe<BooleanFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  indeterminate_minutes?: InputMaybe<IntFilterInput>;
  last_connected?: InputMaybe<DateTimeFilterInput>;
  mac_address?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<BeaconFiltersInput>;
  online_minutes?: InputMaybe<IntFilterInput>;
  or?: InputMaybe<Array<InputMaybe<BeaconFiltersInput>>>;
  people?: InputMaybe<PersonFiltersInput>;
  primary_image?: InputMaybe<IntFilterInput>;
  registration_number?: InputMaybe<StringFilterInput>;
  routes?: InputMaybe<RouteFiltersInput>;
  serial_number?: InputMaybe<StringFilterInput>;
  tags?: InputMaybe<TagFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  vin?: InputMaybe<StringFilterInput>;
};

export type BeaconInput = {
  alarms?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  beacon_model?: InputMaybe<Scalars['ID']>;
  beacon_values?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  connected_units?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  customer?: InputMaybe<Scalars['ID']>;
  customer_reference_number?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<Scalars['ID']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  indeterminate_minutes?: InputMaybe<Scalars['Int']>;
  last_connected?: InputMaybe<Scalars['DateTime']>;
  mac_address?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  online_minutes?: InputMaybe<Scalars['Int']>;
  people?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  primary_image?: InputMaybe<Scalars['Int']>;
  registration_number?: InputMaybe<Scalars['String']>;
  routes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  serial_number?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  vin?: InputMaybe<Scalars['String']>;
};

export type BeaconModel = {
  __typename?: 'BeaconModel';
  beacon_parameters?: Maybe<BeaconParameterRelationResponseCollection>;
  beacons?: Maybe<BeaconRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<ManufacturerEntityResponse>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type BeaconModelBeacon_ParametersArgs = {
  filters?: InputMaybe<BeaconParameterFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type BeaconModelBeaconsArgs = {
  filters?: InputMaybe<BeaconFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BeaconModelEntity = {
  __typename?: 'BeaconModelEntity';
  attributes?: Maybe<BeaconModel>;
  id?: Maybe<Scalars['ID']>;
};

export type BeaconModelEntityResponse = {
  __typename?: 'BeaconModelEntityResponse';
  data?: Maybe<BeaconModelEntity>;
};

export type BeaconModelEntityResponseCollection = {
  __typename?: 'BeaconModelEntityResponseCollection';
  data: Array<BeaconModelEntity>;
  meta: ResponseCollectionMeta;
};

export type BeaconModelFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<BeaconModelFiltersInput>>>;
  beacon_parameters?: InputMaybe<BeaconParameterFiltersInput>;
  beacons?: InputMaybe<BeaconFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  manufacturer?: InputMaybe<ManufacturerFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<BeaconModelFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<BeaconModelFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type BeaconModelInput = {
  beacon_parameters?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  beacons?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  manufacturer?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type BeaconModelRelationResponseCollection = {
  __typename?: 'BeaconModelRelationResponseCollection';
  data: Array<BeaconModelEntity>;
};

export type BeaconParameter = {
  __typename?: 'BeaconParameter';
  beacon_models?: Maybe<BeaconModelRelationResponseCollection>;
  beacon_values?: Maybe<BeaconValueRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  key: Enum_Beaconparameter_Key;
  name: Scalars['String'];
  unit?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type BeaconParameterBeacon_ModelsArgs = {
  filters?: InputMaybe<BeaconModelFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type BeaconParameterBeacon_ValuesArgs = {
  filters?: InputMaybe<BeaconValueFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BeaconParameterEntity = {
  __typename?: 'BeaconParameterEntity';
  attributes?: Maybe<BeaconParameter>;
  id?: Maybe<Scalars['ID']>;
};

export type BeaconParameterEntityResponse = {
  __typename?: 'BeaconParameterEntityResponse';
  data?: Maybe<BeaconParameterEntity>;
};

export type BeaconParameterEntityResponseCollection = {
  __typename?: 'BeaconParameterEntityResponseCollection';
  data: Array<BeaconParameterEntity>;
  meta: ResponseCollectionMeta;
};

export type BeaconParameterFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<BeaconParameterFiltersInput>>>;
  beacon_models?: InputMaybe<BeaconModelFiltersInput>;
  beacon_values?: InputMaybe<BeaconValueFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  key?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<BeaconParameterFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<BeaconParameterFiltersInput>>>;
  unit?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type BeaconParameterInput = {
  beacon_models?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  beacon_values?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  key?: InputMaybe<Enum_Beaconparameter_Key>;
  name?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
};

export type BeaconParameterRelationResponseCollection = {
  __typename?: 'BeaconParameterRelationResponseCollection';
  data: Array<BeaconParameterEntity>;
};

export type BeaconRelationResponseCollection = {
  __typename?: 'BeaconRelationResponseCollection';
  data: Array<BeaconEntity>;
};

export type BeaconValue = {
  __typename?: 'BeaconValue';
  beacon?: Maybe<BeaconEntityResponse>;
  beacon_parameter?: Maybe<BeaconParameterEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['String'];
};

export type BeaconValueEntity = {
  __typename?: 'BeaconValueEntity';
  attributes?: Maybe<BeaconValue>;
  id?: Maybe<Scalars['ID']>;
};

export type BeaconValueEntityResponse = {
  __typename?: 'BeaconValueEntityResponse';
  data?: Maybe<BeaconValueEntity>;
};

export type BeaconValueEntityResponseCollection = {
  __typename?: 'BeaconValueEntityResponseCollection';
  data: Array<BeaconValueEntity>;
  meta: ResponseCollectionMeta;
};

export type BeaconValueFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<BeaconValueFiltersInput>>>;
  beacon?: InputMaybe<BeaconFiltersInput>;
  beacon_parameter?: InputMaybe<BeaconParameterFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<BeaconValueFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<BeaconValueFiltersInput>>>;
  timestamp?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  value?: InputMaybe<StringFilterInput>;
};

export type BeaconValueInput = {
  beacon?: InputMaybe<Scalars['ID']>;
  beacon_parameter?: InputMaybe<Scalars['ID']>;
  timestamp?: InputMaybe<Scalars['DateTime']>;
  value?: InputMaybe<Scalars['String']>;
};

export type BeaconValueRelationResponseCollection = {
  __typename?: 'BeaconValueRelationResponseCollection';
  data: Array<BeaconValueEntity>;
};

export type BooleanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  contains?: InputMaybe<Scalars['Boolean']>;
  containsi?: InputMaybe<Scalars['Boolean']>;
  endsWith?: InputMaybe<Scalars['Boolean']>;
  eq?: InputMaybe<Scalars['Boolean']>;
  eqi?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['Boolean']>;
  gte?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  lt?: InputMaybe<Scalars['Boolean']>;
  lte?: InputMaybe<Scalars['Boolean']>;
  ne?: InputMaybe<Scalars['Boolean']>;
  nei?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<BooleanFilterInput>;
  notContains?: InputMaybe<Scalars['Boolean']>;
  notContainsi?: InputMaybe<Scalars['Boolean']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  startsWith?: InputMaybe<Scalars['Boolean']>;
};

export type Case = {
  __typename?: 'Case';
  case_items?: Maybe<CaseItemRelationResponseCollection>;
  case_number?: Maybe<Scalars['String']>;
  closed?: Maybe<Scalars['DateTime']>;
  color?: Maybe<Scalars['String']>;
  comments?: Maybe<CommentRelationResponseCollection>;
  course_description?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crime_address?: Maybe<AddressEntityResponse>;
  crime_time?: Maybe<Scalars['DateTime']>;
  customer?: Maybe<CustomerEntityResponse>;
  customer_contacted_at?: Maybe<Scalars['DateTime']>;
  customer_internal_case_number?: Maybe<Scalars['String']>;
  ensurance_company_case_number?: Maybe<Scalars['String']>;
  ensurance_company_name?: Maybe<Scalars['String']>;
  files?: Maybe<UploadFileRelationResponseCollection>;
  found_address?: Maybe<AddressEntityResponse>;
  found_by?: Maybe<Scalars['String']>;
  found_time?: Maybe<Scalars['DateTime']>;
  images?: Maybe<UploadFileRelationResponseCollection>;
  item?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  new_price?: Maybe<Scalars['Float']>;
  notification_receivers?: Maybe<UsersPermissionsUserRelationResponseCollection>;
  police_j_number?: Maybe<Scalars['String']>;
  replacement_price?: Maybe<Scalars['Float']>;
  serial_number?: Maybe<Scalars['String']>;
  sg_internal_case_number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};


export type CaseCase_ItemsArgs = {
  filters?: InputMaybe<CaseItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CaseCommentsArgs = {
  filters?: InputMaybe<CommentFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CaseFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CaseImagesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CaseNotification_ReceiversArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CaseEntity = {
  __typename?: 'CaseEntity';
  attributes?: Maybe<Case>;
  id?: Maybe<Scalars['ID']>;
};

export type CaseEntityResponse = {
  __typename?: 'CaseEntityResponse';
  data?: Maybe<CaseEntity>;
};

export type CaseEntityResponseCollection = {
  __typename?: 'CaseEntityResponseCollection';
  data: Array<CaseEntity>;
  meta: ResponseCollectionMeta;
};

export type CaseFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CaseFiltersInput>>>;
  case_items?: InputMaybe<CaseItemFiltersInput>;
  case_number?: InputMaybe<StringFilterInput>;
  closed?: InputMaybe<DateTimeFilterInput>;
  color?: InputMaybe<StringFilterInput>;
  comments?: InputMaybe<CommentFiltersInput>;
  course_description?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  crime_address?: InputMaybe<AddressFiltersInput>;
  crime_time?: InputMaybe<DateTimeFilterInput>;
  customer?: InputMaybe<CustomerFiltersInput>;
  customer_contacted_at?: InputMaybe<DateTimeFilterInput>;
  customer_internal_case_number?: InputMaybe<StringFilterInput>;
  ensurance_company_case_number?: InputMaybe<StringFilterInput>;
  ensurance_company_name?: InputMaybe<StringFilterInput>;
  found_address?: InputMaybe<AddressFiltersInput>;
  found_by?: InputMaybe<StringFilterInput>;
  found_time?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  item?: InputMaybe<StringFilterInput>;
  model?: InputMaybe<StringFilterInput>;
  new_price?: InputMaybe<FloatFilterInput>;
  not?: InputMaybe<CaseFiltersInput>;
  notification_receivers?: InputMaybe<UsersPermissionsUserFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CaseFiltersInput>>>;
  police_j_number?: InputMaybe<StringFilterInput>;
  replacement_price?: InputMaybe<FloatFilterInput>;
  serial_number?: InputMaybe<StringFilterInput>;
  sg_internal_case_number?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  vin?: InputMaybe<StringFilterInput>;
  year?: InputMaybe<IntFilterInput>;
};

export type CaseInput = {
  case_items?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  case_number?: InputMaybe<Scalars['String']>;
  closed?: InputMaybe<Scalars['DateTime']>;
  color?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  course_description?: InputMaybe<Scalars['String']>;
  crime_address?: InputMaybe<Scalars['ID']>;
  crime_time?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<Scalars['ID']>;
  customer_contacted_at?: InputMaybe<Scalars['DateTime']>;
  customer_internal_case_number?: InputMaybe<Scalars['String']>;
  ensurance_company_case_number?: InputMaybe<Scalars['String']>;
  ensurance_company_name?: InputMaybe<Scalars['String']>;
  files?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  found_address?: InputMaybe<Scalars['ID']>;
  found_by?: InputMaybe<Scalars['String']>;
  found_time?: InputMaybe<Scalars['DateTime']>;
  images?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  item?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  new_price?: InputMaybe<Scalars['Float']>;
  notification_receivers?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  police_j_number?: InputMaybe<Scalars['String']>;
  replacement_price?: InputMaybe<Scalars['Float']>;
  serial_number?: InputMaybe<Scalars['String']>;
  sg_internal_case_number?: InputMaybe<Scalars['String']>;
  vin?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type CaseItem = {
  __typename?: 'CaseItem';
  assisted_recovery?: Maybe<Scalars['Boolean']>;
  case?: Maybe<CaseEntityResponse>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  images?: Maybe<UploadFileRelationResponseCollection>;
  model?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  new_price?: Maybe<Scalars['Float']>;
  replacement_price?: Maybe<Scalars['Float']>;
  serial_number?: Maybe<Scalars['String']>;
  technical_recovery?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
};


export type CaseItemImagesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CaseItemEntity = {
  __typename?: 'CaseItemEntity';
  attributes?: Maybe<CaseItem>;
  id?: Maybe<Scalars['ID']>;
};

export type CaseItemEntityResponse = {
  __typename?: 'CaseItemEntityResponse';
  data?: Maybe<CaseItemEntity>;
};

export type CaseItemEntityResponseCollection = {
  __typename?: 'CaseItemEntityResponseCollection';
  data: Array<CaseItemEntity>;
  meta: ResponseCollectionMeta;
};

export type CaseItemFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CaseItemFiltersInput>>>;
  assisted_recovery?: InputMaybe<BooleanFilterInput>;
  case?: InputMaybe<CaseFiltersInput>;
  color?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  model?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  new_price?: InputMaybe<FloatFilterInput>;
  not?: InputMaybe<CaseItemFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CaseItemFiltersInput>>>;
  replacement_price?: InputMaybe<FloatFilterInput>;
  serial_number?: InputMaybe<StringFilterInput>;
  technical_recovery?: InputMaybe<BooleanFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  vin?: InputMaybe<StringFilterInput>;
  year?: InputMaybe<IntFilterInput>;
};

export type CaseItemInput = {
  assisted_recovery?: InputMaybe<Scalars['Boolean']>;
  case?: InputMaybe<Scalars['ID']>;
  color?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  model?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  new_price?: InputMaybe<Scalars['Float']>;
  replacement_price?: InputMaybe<Scalars['Float']>;
  serial_number?: InputMaybe<Scalars['String']>;
  technical_recovery?: InputMaybe<Scalars['Boolean']>;
  vin?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type CaseItemRelationResponseCollection = {
  __typename?: 'CaseItemRelationResponseCollection';
  data: Array<CaseItemEntity>;
};

export type CaseRelationResponseCollection = {
  __typename?: 'CaseRelationResponseCollection';
  data: Array<CaseEntity>;
};

export type Comment = {
  __typename?: 'Comment';
  body: Scalars['String'];
  case?: Maybe<CaseEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UsersPermissionsUserEntityResponse>;
};

export type CommentEntity = {
  __typename?: 'CommentEntity';
  attributes?: Maybe<Comment>;
  id?: Maybe<Scalars['ID']>;
};

export type CommentEntityResponse = {
  __typename?: 'CommentEntityResponse';
  data?: Maybe<CommentEntity>;
};

export type CommentEntityResponseCollection = {
  __typename?: 'CommentEntityResponseCollection';
  data: Array<CommentEntity>;
  meta: ResponseCollectionMeta;
};

export type CommentFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CommentFiltersInput>>>;
  body?: InputMaybe<StringFilterInput>;
  case?: InputMaybe<CaseFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<CommentFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CommentFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  user?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type CommentInput = {
  body?: InputMaybe<Scalars['String']>;
  case?: InputMaybe<Scalars['ID']>;
  user?: InputMaybe<Scalars['ID']>;
};

export type CommentRelationResponseCollection = {
  __typename?: 'CommentRelationResponseCollection';
  data: Array<CommentEntity>;
};

export type ComponentAccessModule = {
  __typename?: 'ComponentAccessModule';
  id: Scalars['ID'];
  name: Enum_Componentaccessmodule_Name;
};

export type ComponentAccessModuleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentAccessModuleFiltersInput>>>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentAccessModuleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentAccessModuleFiltersInput>>>;
};

export type ComponentAccessModuleInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Enum_Componentaccessmodule_Name>;
};

export type ComponentAccessPermission = {
  __typename?: 'ComponentAccessPermission';
  create: Scalars['Boolean'];
  delete: Scalars['Boolean'];
  id: Scalars['ID'];
  object?: Maybe<Enum_Componentaccesspermission_Object>;
  read: Scalars['Boolean'];
  update: Scalars['Boolean'];
};

export type ComponentAccessPermissionFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentAccessPermissionFiltersInput>>>;
  create?: InputMaybe<BooleanFilterInput>;
  delete?: InputMaybe<BooleanFilterInput>;
  not?: InputMaybe<ComponentAccessPermissionFiltersInput>;
  object?: InputMaybe<StringFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentAccessPermissionFiltersInput>>>;
  read?: InputMaybe<BooleanFilterInput>;
  update?: InputMaybe<BooleanFilterInput>;
};

export type ComponentAccessPermissionInput = {
  create?: InputMaybe<Scalars['Boolean']>;
  delete?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  object?: InputMaybe<Enum_Componentaccesspermission_Object>;
  read?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<Scalars['Boolean']>;
};

export type Customer = {
  __typename?: 'Customer';
  beacons?: Maybe<BeaconRelationResponseCollection>;
  cases?: Maybe<CaseRelationResponseCollection>;
  city?: Maybe<Scalars['String']>;
  contact_email?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  contact_phone?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  data_termination_days?: Maybe<Scalars['Int']>;
  groups?: Maybe<GroupRelationResponseCollection>;
  integrations?: Maybe<IntegrationRelationResponseCollection>;
  name: Scalars['String'];
  people?: Maybe<PersonRelationResponseCollection>;
  reports?: Maybe<ReportRelationResponseCollection>;
  street?: Maybe<Scalars['String']>;
  tags?: Maybe<TagRelationResponseCollection>;
  units?: Maybe<UnitRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user_groups?: Maybe<UserGroupRelationResponseCollection>;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
  vat_number?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};


export type CustomerBeaconsArgs = {
  filters?: InputMaybe<BeaconFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CustomerCasesArgs = {
  filters?: InputMaybe<CaseFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CustomerGroupsArgs = {
  filters?: InputMaybe<GroupFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CustomerIntegrationsArgs = {
  filters?: InputMaybe<IntegrationFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CustomerPeopleArgs = {
  filters?: InputMaybe<PersonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CustomerReportsArgs = {
  filters?: InputMaybe<ReportFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CustomerTagsArgs = {
  filters?: InputMaybe<TagFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CustomerUnitsArgs = {
  filters?: InputMaybe<UnitFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CustomerUser_GroupsArgs = {
  filters?: InputMaybe<UserGroupFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CustomerUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CustomerEntity = {
  __typename?: 'CustomerEntity';
  attributes?: Maybe<Customer>;
  id?: Maybe<Scalars['ID']>;
};

export type CustomerEntityResponse = {
  __typename?: 'CustomerEntityResponse';
  data?: Maybe<CustomerEntity>;
};

export type CustomerEntityResponseCollection = {
  __typename?: 'CustomerEntityResponseCollection';
  data: Array<CustomerEntity>;
  meta: ResponseCollectionMeta;
};

export type CustomerFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CustomerFiltersInput>>>;
  beacons?: InputMaybe<BeaconFiltersInput>;
  cases?: InputMaybe<CaseFiltersInput>;
  city?: InputMaybe<StringFilterInput>;
  contact_email?: InputMaybe<StringFilterInput>;
  contact_name?: InputMaybe<StringFilterInput>;
  contact_phone?: InputMaybe<StringFilterInput>;
  country?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  data_termination_days?: InputMaybe<IntFilterInput>;
  groups?: InputMaybe<GroupFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  integrations?: InputMaybe<IntegrationFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<CustomerFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CustomerFiltersInput>>>;
  people?: InputMaybe<PersonFiltersInput>;
  reports?: InputMaybe<ReportFiltersInput>;
  street?: InputMaybe<StringFilterInput>;
  tags?: InputMaybe<TagFiltersInput>;
  units?: InputMaybe<UnitFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  user_groups?: InputMaybe<UserGroupFiltersInput>;
  users?: InputMaybe<UsersPermissionsUserFiltersInput>;
  vat_number?: InputMaybe<StringFilterInput>;
  zip?: InputMaybe<StringFilterInput>;
};

export type CustomerInput = {
  beacons?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  cases?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  city?: InputMaybe<Scalars['String']>;
  contact_email?: InputMaybe<Scalars['String']>;
  contact_name?: InputMaybe<Scalars['String']>;
  contact_phone?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  data_termination_days?: InputMaybe<Scalars['Int']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  integrations?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
  people?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  reports?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  street?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  units?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  user_groups?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  vat_number?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type DateFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  contains?: InputMaybe<Scalars['Date']>;
  containsi?: InputMaybe<Scalars['Date']>;
  endsWith?: InputMaybe<Scalars['Date']>;
  eq?: InputMaybe<Scalars['Date']>;
  eqi?: InputMaybe<Scalars['Date']>;
  gt?: InputMaybe<Scalars['Date']>;
  gte?: InputMaybe<Scalars['Date']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  lt?: InputMaybe<Scalars['Date']>;
  lte?: InputMaybe<Scalars['Date']>;
  ne?: InputMaybe<Scalars['Date']>;
  nei?: InputMaybe<Scalars['Date']>;
  not?: InputMaybe<DateFilterInput>;
  notContains?: InputMaybe<Scalars['Date']>;
  notContainsi?: InputMaybe<Scalars['Date']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  startsWith?: InputMaybe<Scalars['Date']>;
};

export type DateTimeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  contains?: InputMaybe<Scalars['DateTime']>;
  containsi?: InputMaybe<Scalars['DateTime']>;
  endsWith?: InputMaybe<Scalars['DateTime']>;
  eq?: InputMaybe<Scalars['DateTime']>;
  eqi?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  ne?: InputMaybe<Scalars['DateTime']>;
  nei?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<DateTimeFilterInput>;
  notContains?: InputMaybe<Scalars['DateTime']>;
  notContainsi?: InputMaybe<Scalars['DateTime']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  startsWith?: InputMaybe<Scalars['DateTime']>;
};

export type Detection = {
  __typename?: 'Detection';
  beacon: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  timestamp: Scalars['DateTime'];
  unit: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type DetectionEntity = {
  __typename?: 'DetectionEntity';
  attributes?: Maybe<Detection>;
  id?: Maybe<Scalars['ID']>;
};

export type DetectionEntityResponse = {
  __typename?: 'DetectionEntityResponse';
  data?: Maybe<DetectionEntity>;
};

export type DetectionEntityResponseCollection = {
  __typename?: 'DetectionEntityResponseCollection';
  data: Array<DetectionEntity>;
  meta: ResponseCollectionMeta;
};

export type DetectionFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<DetectionFiltersInput>>>;
  beacon?: InputMaybe<IntFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<DetectionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<DetectionFiltersInput>>>;
  terminate?: InputMaybe<DateTimeFilterInput>;
  timestamp?: InputMaybe<DateTimeFilterInput>;
  unit?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type DetectionInput = {
  beacon?: InputMaybe<Scalars['Int']>;
  terminate?: InputMaybe<Scalars['DateTime']>;
  timestamp?: InputMaybe<Scalars['DateTime']>;
  unit?: InputMaybe<Scalars['Int']>;
};

export enum Enum_Alarmevent_State {
  Resolved = 'resolved',
  Triggered = 'triggered'
}

export enum Enum_Alarm_Type {
  ConnectedBeacons = 'connected_beacons',
  StatusChange = 'status_change',
  UnplugDetection = 'unplug_detection'
}

export enum Enum_Beaconparameter_Key {
  BatteryVoltage = 'battery_voltage',
  Rssi = 'rssi'
}

export enum Enum_Componentaccessmodule_Name {
  Alarms = 'alarms',
  Beacons = 'beacons',
  Cases = 'cases',
  Customers = 'customers',
  Dashboard = 'dashboard',
  Groups = 'groups',
  Map = 'map',
  People = 'people',
  Profile = 'profile',
  Reports = 'reports',
  Settings = 'settings',
  Telenor = 'telenor',
  Units = 'units',
  Users = 'users'
}

export enum Enum_Componentaccesspermission_Object {
  Alarm = 'alarm',
  Beacon = 'beacon',
  Case = 'case',
  Comment = 'comment',
  Customer = 'customer',
  Group = 'group',
  Person = 'person',
  Report = 'report',
  Tag = 'tag',
  Unit = 'unit',
  UnitModel = 'unit_model',
  User = 'user',
  UserGroup = 'user_group'
}

export enum Enum_Integration_Key {
  Trackunit = 'trackunit'
}

export enum Enum_Notification_Type {
  App = 'app',
  Email = 'email',
  Sms = 'sms'
}

export enum Enum_Report_Generation_Day {
  Friday = 'friday',
  Monday = 'monday',
  Saturday = 'saturday',
  Sunday = 'sunday',
  Thursday = 'thursday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday'
}

export enum Enum_Report_Generation_Type {
  Single = 'single',
  Weekly = 'weekly'
}

export enum Enum_Report_Type {
  EngineHours = 'engine_hours',
  Routes = 'routes'
}

export enum Enum_Unitmodel_Parser {
  QueclinkTrackAirInterface = 'QueclinkTrackAirInterface',
  TeltonikaCodec8e = 'TeltonikaCodec8e'
}

export enum Enum_Unitparameter_Type {
  Ascii = 'ascii',
  Hex = 'hex',
  Signed = 'signed',
  Unsigned = 'unsigned'
}

export enum Enum_Unit_Installation_State {
  Installed = 'installed',
  Ready = 'ready',
  Removed = 'removed'
}

export type FileInfoInput = {
  alternativeText?: InputMaybe<Scalars['String']>;
  caption?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type FloatFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  contains?: InputMaybe<Scalars['Float']>;
  containsi?: InputMaybe<Scalars['Float']>;
  endsWith?: InputMaybe<Scalars['Float']>;
  eq?: InputMaybe<Scalars['Float']>;
  eqi?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  ne?: InputMaybe<Scalars['Float']>;
  nei?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<FloatFilterInput>;
  notContains?: InputMaybe<Scalars['Float']>;
  notContainsi?: InputMaybe<Scalars['Float']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  startsWith?: InputMaybe<Scalars['Float']>;
};

export type GenericMorph = Address | Alarm | AlarmEvent | Beacon | BeaconModel | BeaconParameter | BeaconValue | Case | CaseItem | Comment | ComponentAccessModule | ComponentAccessPermission | Customer | Detection | Group | I18NLocale | Integration | Location | Log | Manufacturer | Notification | Packet | Person | Report | ReportResult | Route | SuccessfulTelemetryExecution | Tag | TeleLog | TeleMonitoring | Unit | UnitModel | UnitParameter | UnitValue | UploadFile | UploadFolder | UserGroup | UsersPermissionsPermission | UsersPermissionsRole | UsersPermissionsUser;

export type Group = {
  __typename?: 'Group';
  beacons?: Maybe<BeaconRelationResponseCollection>;
  color: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  customer?: Maybe<CustomerEntityResponse>;
  hidden?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  tags?: Maybe<TagRelationResponseCollection>;
  units?: Maybe<UnitRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type GroupBeaconsArgs = {
  filters?: InputMaybe<BeaconFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type GroupTagsArgs = {
  filters?: InputMaybe<TagFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type GroupUnitsArgs = {
  filters?: InputMaybe<UnitFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type GroupEntity = {
  __typename?: 'GroupEntity';
  attributes?: Maybe<Group>;
  id?: Maybe<Scalars['ID']>;
};

export type GroupEntityResponse = {
  __typename?: 'GroupEntityResponse';
  data?: Maybe<GroupEntity>;
};

export type GroupEntityResponseCollection = {
  __typename?: 'GroupEntityResponseCollection';
  data: Array<GroupEntity>;
  meta: ResponseCollectionMeta;
};

export type GroupFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<GroupFiltersInput>>>;
  beacons?: InputMaybe<BeaconFiltersInput>;
  color?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  customer?: InputMaybe<CustomerFiltersInput>;
  hidden?: InputMaybe<BooleanFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<GroupFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<GroupFiltersInput>>>;
  tags?: InputMaybe<TagFiltersInput>;
  units?: InputMaybe<UnitFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type GroupInput = {
  beacons?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  color?: InputMaybe<Scalars['String']>;
  customer?: InputMaybe<Scalars['ID']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  units?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type GroupRelationResponseCollection = {
  __typename?: 'GroupRelationResponseCollection';
  data: Array<GroupEntity>;
};

export type I18NLocale = {
  __typename?: 'I18NLocale';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type I18NLocaleEntity = {
  __typename?: 'I18NLocaleEntity';
  attributes?: Maybe<I18NLocale>;
  id?: Maybe<Scalars['ID']>;
};

export type I18NLocaleEntityResponse = {
  __typename?: 'I18NLocaleEntityResponse';
  data?: Maybe<I18NLocaleEntity>;
};

export type I18NLocaleEntityResponseCollection = {
  __typename?: 'I18NLocaleEntityResponseCollection';
  data: Array<I18NLocaleEntity>;
  meta: ResponseCollectionMeta;
};

export type I18NLocaleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  code?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<I18NLocaleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type IdFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contains?: InputMaybe<Scalars['ID']>;
  containsi?: InputMaybe<Scalars['ID']>;
  endsWith?: InputMaybe<Scalars['ID']>;
  eq?: InputMaybe<Scalars['ID']>;
  eqi?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  gte?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  lt?: InputMaybe<Scalars['ID']>;
  lte?: InputMaybe<Scalars['ID']>;
  ne?: InputMaybe<Scalars['ID']>;
  nei?: InputMaybe<Scalars['ID']>;
  not?: InputMaybe<IdFilterInput>;
  notContains?: InputMaybe<Scalars['ID']>;
  notContainsi?: InputMaybe<Scalars['ID']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  startsWith?: InputMaybe<Scalars['ID']>;
};

export type IntFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  contains?: InputMaybe<Scalars['Int']>;
  containsi?: InputMaybe<Scalars['Int']>;
  endsWith?: InputMaybe<Scalars['Int']>;
  eq?: InputMaybe<Scalars['Int']>;
  eqi?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
  nei?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<IntFilterInput>;
  notContains?: InputMaybe<Scalars['Int']>;
  notContainsi?: InputMaybe<Scalars['Int']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  startsWith?: InputMaybe<Scalars['Int']>;
};

export type Integration = {
  __typename?: 'Integration';
  configuration: Scalars['JSON'];
  createdAt?: Maybe<Scalars['DateTime']>;
  customer?: Maybe<CustomerEntityResponse>;
  enable_time?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Enum_Integration_Key>;
  state?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UsersPermissionsUserEntityResponse>;
};

export type IntegrationEntity = {
  __typename?: 'IntegrationEntity';
  attributes?: Maybe<Integration>;
  id?: Maybe<Scalars['ID']>;
};

export type IntegrationEntityResponse = {
  __typename?: 'IntegrationEntityResponse';
  data?: Maybe<IntegrationEntity>;
};

export type IntegrationEntityResponseCollection = {
  __typename?: 'IntegrationEntityResponseCollection';
  data: Array<IntegrationEntity>;
  meta: ResponseCollectionMeta;
};

export type IntegrationFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IntegrationFiltersInput>>>;
  configuration?: InputMaybe<JsonFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  customer?: InputMaybe<CustomerFiltersInput>;
  enable_time?: InputMaybe<DateTimeFilterInput>;
  enabled?: InputMaybe<BooleanFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  key?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<IntegrationFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<IntegrationFiltersInput>>>;
  state?: InputMaybe<JsonFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  user?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type IntegrationInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  customer?: InputMaybe<Scalars['ID']>;
  enable_time?: InputMaybe<Scalars['DateTime']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Enum_Integration_Key>;
  state?: InputMaybe<Scalars['JSON']>;
  user?: InputMaybe<Scalars['ID']>;
};

export type IntegrationRelationResponseCollection = {
  __typename?: 'IntegrationRelationResponseCollection';
  data: Array<IntegrationEntity>;
};

export type JsonFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  contains?: InputMaybe<Scalars['JSON']>;
  containsi?: InputMaybe<Scalars['JSON']>;
  endsWith?: InputMaybe<Scalars['JSON']>;
  eq?: InputMaybe<Scalars['JSON']>;
  eqi?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  ne?: InputMaybe<Scalars['JSON']>;
  nei?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<JsonFilterInput>;
  notContains?: InputMaybe<Scalars['JSON']>;
  notContainsi?: InputMaybe<Scalars['JSON']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  startsWith?: InputMaybe<Scalars['JSON']>;
};

export type LatestUnitValue = {
  __typename?: 'LatestUnitValue';
  id: Scalars['ID'];
  name: Scalars['String'];
  parameterId: Scalars['Int'];
  timestamp: Scalars['DateTime'];
  unit: Scalars['String'];
  value: Scalars['String'];
};

export type Location = {
  __typename?: 'Location';
  address?: Maybe<AddressEntityResponse>;
  address_id?: Maybe<Scalars['Int']>;
  altitude?: Maybe<Scalars['Int']>;
  angle?: Maybe<Scalars['Int']>;
  beacon?: Maybe<BeaconEntityResponse>;
  beacon_id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  route?: Maybe<RouteEntityResponse>;
  satellites?: Maybe<Scalars['Int']>;
  speed?: Maybe<Scalars['Int']>;
  timestamp: Scalars['DateTime'];
  unit?: Maybe<UnitEntityResponse>;
  unit_id?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LocationEntity = {
  __typename?: 'LocationEntity';
  attributes?: Maybe<Location>;
  id?: Maybe<Scalars['ID']>;
};

export type LocationEntityResponse = {
  __typename?: 'LocationEntityResponse';
  data?: Maybe<LocationEntity>;
};

export type LocationEntityResponseCollection = {
  __typename?: 'LocationEntityResponseCollection';
  data: Array<LocationEntity>;
  meta: ResponseCollectionMeta;
};

export type LocationFiltersInput = {
  address_id?: InputMaybe<IntFilterInput>;
  altitude?: InputMaybe<IntFilterInput>;
  and?: InputMaybe<Array<InputMaybe<LocationFiltersInput>>>;
  angle?: InputMaybe<IntFilterInput>;
  beacon_id?: InputMaybe<IntFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  latitude?: InputMaybe<FloatFilterInput>;
  longitude?: InputMaybe<FloatFilterInput>;
  not?: InputMaybe<LocationFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<LocationFiltersInput>>>;
  route?: InputMaybe<RouteFiltersInput>;
  satellites?: InputMaybe<IntFilterInput>;
  speed?: InputMaybe<IntFilterInput>;
  timestamp?: InputMaybe<DateTimeFilterInput>;
  unit_id?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type LocationInput = {
  address_id?: InputMaybe<Scalars['Int']>;
  altitude?: InputMaybe<Scalars['Int']>;
  angle?: InputMaybe<Scalars['Int']>;
  beacon_id?: InputMaybe<Scalars['Int']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  route?: InputMaybe<Scalars['ID']>;
  satellites?: InputMaybe<Scalars['Int']>;
  speed?: InputMaybe<Scalars['Int']>;
  timestamp?: InputMaybe<Scalars['DateTime']>;
  unit_id?: InputMaybe<Scalars['Int']>;
};

export type LocationRelationResponseCollection = {
  __typename?: 'LocationRelationResponseCollection';
  data: Array<LocationEntity>;
};

export type Log = {
  __typename?: 'Log';
  createdAt?: Maybe<Scalars['DateTime']>;
  query?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user_id?: Maybe<Scalars['Int']>;
  variables?: Maybe<Scalars['JSON']>;
};

export type LogEntity = {
  __typename?: 'LogEntity';
  attributes?: Maybe<Log>;
  id?: Maybe<Scalars['ID']>;
};

export type LogEntityResponse = {
  __typename?: 'LogEntityResponse';
  data?: Maybe<LogEntity>;
};

export type LogEntityResponseCollection = {
  __typename?: 'LogEntityResponseCollection';
  data: Array<LogEntity>;
  meta: ResponseCollectionMeta;
};

export type LogFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<LogFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<LogFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<LogFiltersInput>>>;
  query?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  user_id?: InputMaybe<IntFilterInput>;
  variables?: InputMaybe<JsonFilterInput>;
};

export type LogInput = {
  query?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['Int']>;
  variables?: InputMaybe<Scalars['JSON']>;
};

export type Manufacturer = {
  __typename?: 'Manufacturer';
  beacon_models?: Maybe<BeaconModelRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  unit_models?: Maybe<UnitModelRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  website?: Maybe<Scalars['String']>;
};


export type ManufacturerBeacon_ModelsArgs = {
  filters?: InputMaybe<BeaconModelFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ManufacturerUnit_ModelsArgs = {
  filters?: InputMaybe<UnitModelFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ManufacturerEntity = {
  __typename?: 'ManufacturerEntity';
  attributes?: Maybe<Manufacturer>;
  id?: Maybe<Scalars['ID']>;
};

export type ManufacturerEntityResponse = {
  __typename?: 'ManufacturerEntityResponse';
  data?: Maybe<ManufacturerEntity>;
};

export type ManufacturerEntityResponseCollection = {
  __typename?: 'ManufacturerEntityResponseCollection';
  data: Array<ManufacturerEntity>;
  meta: ResponseCollectionMeta;
};

export type ManufacturerFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ManufacturerFiltersInput>>>;
  beacon_models?: InputMaybe<BeaconModelFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ManufacturerFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ManufacturerFiltersInput>>>;
  unit_models?: InputMaybe<UnitModelFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  website?: InputMaybe<StringFilterInput>;
};

export type ManufacturerInput = {
  beacon_models?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  unit_models?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  website?: InputMaybe<Scalars['String']>;
};

export type MapObject = {
  __typename?: 'MapObject';
  group?: Maybe<MapObjectGroup>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  identifier: Scalars['String'];
  image?: Maybe<MapObjectImage>;
  indeterminate_minutes?: Maybe<Scalars['Int']>;
  last_connected?: Maybe<Scalars['DateTime']>;
  location?: Maybe<MapObjectLocation>;
  name?: Maybe<Scalars['String']>;
  online_minutes?: Maybe<Scalars['Int']>;
  people: Array<Maybe<MapObjectPerson>>;
};

export type MapObjectFiltersInput = {
  id?: InputMaybe<StringFilterInput>;
  type?: InputMaybe<Scalars['String']>;
};

export type MapObjectGroup = {
  __typename?: 'MapObjectGroup';
  color: Scalars['String'];
  id: Scalars['ID'];
  items: Array<MapObject>;
  name: Scalars['String'];
};


export type MapObjectGroupItemsArgs = {
  searchQuery?: InputMaybe<Scalars['String']>;
};

export type MapObjectGroupsResponse = {
  __typename?: 'MapObjectGroupsResponse';
  data: Array<Maybe<MapObjectGroup>>;
  pagination: Pagination;
};

export type MapObjectImage = {
  __typename?: 'MapObjectImage';
  formats?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type MapObjectLocation = {
  __typename?: 'MapObjectLocation';
  address?: Maybe<MapObjectLocationAddress>;
  id: Scalars['ID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  timestamp: Scalars['DateTime'];
};

export type MapObjectLocationAddress = {
  __typename?: 'MapObjectLocationAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  number?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type MapObjectPerson = {
  __typename?: 'MapObjectPerson';
  email?: Maybe<Scalars['String']>;
  first_name: Scalars['String'];
  id: Scalars['ID'];
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type MapObjectsResponse = {
  __typename?: 'MapObjectsResponse';
  data: Array<Maybe<MapObject>>;
  pagination: Pagination;
};

export type Mutation = {
  __typename?: 'Mutation';
  activateUser?: Maybe<Scalars['String']>;
  /** Change user password. Confirm with the current password. */
  changePassword?: Maybe<UsersPermissionsLoginPayload>;
  createAddress?: Maybe<AddressEntityResponse>;
  createAlarm?: Maybe<AlarmEntityResponse>;
  createAlarmEvent?: Maybe<AlarmEventEntityResponse>;
  createBeacon?: Maybe<BeaconEntityResponse>;
  createBeaconModel?: Maybe<BeaconModelEntityResponse>;
  createBeaconParameter?: Maybe<BeaconParameterEntityResponse>;
  createBeaconValue?: Maybe<BeaconValueEntityResponse>;
  createCase?: Maybe<CaseEntityResponse>;
  createCaseItem?: Maybe<CaseItemEntityResponse>;
  createComment?: Maybe<CommentEntityResponse>;
  createCustomer?: Maybe<CustomerEntityResponse>;
  createDetection?: Maybe<DetectionEntityResponse>;
  createGroup?: Maybe<GroupEntityResponse>;
  createIntegration?: Maybe<IntegrationEntityResponse>;
  createLocation?: Maybe<LocationEntityResponse>;
  createLog?: Maybe<LogEntityResponse>;
  createManufacturer?: Maybe<ManufacturerEntityResponse>;
  createNotification?: Maybe<NotificationEntityResponse>;
  createPacket?: Maybe<PacketEntityResponse>;
  createPerson?: Maybe<PersonEntityResponse>;
  createReport?: Maybe<ReportEntityResponse>;
  createReportResult?: Maybe<ReportResultEntityResponse>;
  createRoute?: Maybe<RouteEntityResponse>;
  createTag?: Maybe<TagEntityResponse>;
  createTeleLog?: Maybe<TeleLogEntityResponse>;
  createTeleMonitoring?: Maybe<TeleMonitoringEntityResponse>;
  createUnit?: Maybe<UnitEntityResponse>;
  createUnitModel?: Maybe<UnitModelEntityResponse>;
  createUnitParameter?: Maybe<UnitParameterEntityResponse>;
  createUnitValue?: Maybe<UnitValueEntityResponse>;
  createUploadFile?: Maybe<UploadFileEntityResponse>;
  createUploadFolder?: Maybe<UploadFolderEntityResponse>;
  createUserGroup?: Maybe<UserGroupEntityResponse>;
  createUserGroupLocalization?: Maybe<UserGroupEntityResponse>;
  /** Create a new role */
  createUsersPermissionsRole?: Maybe<UsersPermissionsCreateRolePayload>;
  /** Create a new user */
  createUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  deleteAddress?: Maybe<AddressEntityResponse>;
  deleteAlarm?: Maybe<AlarmEntityResponse>;
  deleteAlarmEvent?: Maybe<AlarmEventEntityResponse>;
  deleteBeacon?: Maybe<BeaconEntityResponse>;
  deleteBeaconModel?: Maybe<BeaconModelEntityResponse>;
  deleteBeaconParameter?: Maybe<BeaconParameterEntityResponse>;
  deleteBeaconValue?: Maybe<BeaconValueEntityResponse>;
  deleteCase?: Maybe<CaseEntityResponse>;
  deleteCaseItem?: Maybe<CaseItemEntityResponse>;
  deleteComment?: Maybe<CommentEntityResponse>;
  deleteCustomer?: Maybe<CustomerEntityResponse>;
  deleteDetection?: Maybe<DetectionEntityResponse>;
  deleteGroup?: Maybe<GroupEntityResponse>;
  deleteIntegration?: Maybe<IntegrationEntityResponse>;
  deleteLocation?: Maybe<LocationEntityResponse>;
  deleteLog?: Maybe<LogEntityResponse>;
  deleteManufacturer?: Maybe<ManufacturerEntityResponse>;
  deleteNotification?: Maybe<NotificationEntityResponse>;
  deletePacket?: Maybe<PacketEntityResponse>;
  deletePerson?: Maybe<PersonEntityResponse>;
  deleteReport?: Maybe<ReportEntityResponse>;
  deleteReportResult?: Maybe<ReportResultEntityResponse>;
  deleteRoute?: Maybe<RouteEntityResponse>;
  deleteSuccessfulTelemetryExecution?: Maybe<SuccessfulTelemetryExecutionEntityResponse>;
  deleteTag?: Maybe<TagEntityResponse>;
  deleteTeleLog?: Maybe<TeleLogEntityResponse>;
  deleteTeleMonitoring?: Maybe<TeleMonitoringEntityResponse>;
  deleteUnit?: Maybe<UnitEntityResponse>;
  deleteUnitModel?: Maybe<UnitModelEntityResponse>;
  deleteUnitParameter?: Maybe<UnitParameterEntityResponse>;
  deleteUnitValue?: Maybe<UnitValueEntityResponse>;
  deleteUploadFile?: Maybe<UploadFileEntityResponse>;
  deleteUploadFolder?: Maybe<UploadFolderEntityResponse>;
  deleteUserGroup?: Maybe<UserGroupEntityResponse>;
  /** Delete an existing role */
  deleteUsersPermissionsRole?: Maybe<UsersPermissionsDeleteRolePayload>;
  /** Delete an existing user */
  deleteUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  /** Confirm an email users email address */
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
  /** Request a reset password token */
  forgotPassword?: Maybe<UsersPermissionsPasswordPayload>;
  login: UsersPermissionsLoginPayload;
  multipleUpload: Array<Maybe<UploadFileEntityResponse>>;
  /** Register a user */
  register: UsersPermissionsLoginPayload;
  removeFile?: Maybe<UploadFileEntityResponse>;
  reportResultPdf: Scalars['String'];
  requestResetPassword: UsersPermissionsPasswordPayload;
  /** Reset user password. Confirm with a code (resetToken from forgotPassword) */
  resetPassword?: Maybe<UsersPermissionsLoginPayload>;
  unitValuesPdf: Scalars['String'];
  updateAddress?: Maybe<AddressEntityResponse>;
  updateAlarm?: Maybe<AlarmEntityResponse>;
  updateAlarmEvent?: Maybe<AlarmEventEntityResponse>;
  updateBeacon?: Maybe<BeaconEntityResponse>;
  updateBeaconModel?: Maybe<BeaconModelEntityResponse>;
  updateBeaconParameter?: Maybe<BeaconParameterEntityResponse>;
  updateBeaconValue?: Maybe<BeaconValueEntityResponse>;
  updateCase?: Maybe<CaseEntityResponse>;
  updateCaseItem?: Maybe<CaseItemEntityResponse>;
  updateComment?: Maybe<CommentEntityResponse>;
  updateCustomer?: Maybe<CustomerEntityResponse>;
  updateDetection?: Maybe<DetectionEntityResponse>;
  updateFileInfo: UploadFileEntityResponse;
  updateGroup?: Maybe<GroupEntityResponse>;
  updateIntegration?: Maybe<IntegrationEntityResponse>;
  updateLocation?: Maybe<LocationEntityResponse>;
  updateLog?: Maybe<LogEntityResponse>;
  updateManufacturer?: Maybe<ManufacturerEntityResponse>;
  updateNotification?: Maybe<NotificationEntityResponse>;
  updatePacket?: Maybe<PacketEntityResponse>;
  updatePerson?: Maybe<PersonEntityResponse>;
  updateReport?: Maybe<ReportEntityResponse>;
  updateReportResult?: Maybe<ReportResultEntityResponse>;
  updateRoute?: Maybe<RouteEntityResponse>;
  updateSuccessfulTelemetryExecution?: Maybe<SuccessfulTelemetryExecutionEntityResponse>;
  updateTag?: Maybe<TagEntityResponse>;
  updateTeleLog?: Maybe<TeleLogEntityResponse>;
  updateTeleMonitoring?: Maybe<TeleMonitoringEntityResponse>;
  updateUnit?: Maybe<UnitEntityResponse>;
  updateUnitModel?: Maybe<UnitModelEntityResponse>;
  updateUnitParameter?: Maybe<UnitParameterEntityResponse>;
  updateUnitValue?: Maybe<UnitValueEntityResponse>;
  updateUploadFile?: Maybe<UploadFileEntityResponse>;
  updateUploadFolder?: Maybe<UploadFolderEntityResponse>;
  updateUserGroup?: Maybe<UserGroupEntityResponse>;
  /** Update an existing role */
  updateUsersPermissionsRole?: Maybe<UsersPermissionsUpdateRolePayload>;
  /** Update an existing user */
  updateUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  upload: UploadFileEntityResponse;
};


export type MutationActivateUserArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


export type MutationCreateAddressArgs = {
  data: AddressInput;
};


export type MutationCreateAlarmArgs = {
  data: AlarmInput;
};


export type MutationCreateAlarmEventArgs = {
  data: AlarmEventInput;
};


export type MutationCreateBeaconArgs = {
  data: BeaconInput;
};


export type MutationCreateBeaconModelArgs = {
  data: BeaconModelInput;
};


export type MutationCreateBeaconParameterArgs = {
  data: BeaconParameterInput;
};


export type MutationCreateBeaconValueArgs = {
  data: BeaconValueInput;
};


export type MutationCreateCaseArgs = {
  data: CaseInput;
};


export type MutationCreateCaseItemArgs = {
  data: CaseItemInput;
};


export type MutationCreateCommentArgs = {
  data: CommentInput;
};


export type MutationCreateCustomerArgs = {
  data: CustomerInput;
};


export type MutationCreateDetectionArgs = {
  data: DetectionInput;
};


export type MutationCreateGroupArgs = {
  data: GroupInput;
};


export type MutationCreateIntegrationArgs = {
  data: IntegrationInput;
};


export type MutationCreateLocationArgs = {
  data: LocationInput;
};


export type MutationCreateLogArgs = {
  data: LogInput;
};


export type MutationCreateManufacturerArgs = {
  data: ManufacturerInput;
};


export type MutationCreateNotificationArgs = {
  data: NotificationInput;
};


export type MutationCreatePacketArgs = {
  data: PacketInput;
};


export type MutationCreatePersonArgs = {
  data: PersonInput;
};


export type MutationCreateReportArgs = {
  data: ReportInput;
};


export type MutationCreateReportResultArgs = {
  data: ReportResultInput;
};


export type MutationCreateRouteArgs = {
  data: RouteInput;
};


export type MutationCreateTagArgs = {
  data: TagInput;
};


export type MutationCreateTeleLogArgs = {
  data: TeleLogInput;
};


export type MutationCreateTeleMonitoringArgs = {
  data: TeleMonitoringInput;
};


export type MutationCreateUnitArgs = {
  data: UnitInput;
};


export type MutationCreateUnitModelArgs = {
  data: UnitModelInput;
};


export type MutationCreateUnitParameterArgs = {
  data: UnitParameterInput;
};


export type MutationCreateUnitValueArgs = {
  data: UnitValueInput;
};


export type MutationCreateUploadFileArgs = {
  data: UploadFileInput;
};


export type MutationCreateUploadFolderArgs = {
  data: UploadFolderInput;
};


export type MutationCreateUserGroupArgs = {
  data: UserGroupInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreateUserGroupLocalizationArgs = {
  data?: InputMaybe<UserGroupInput>;
  id?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
};


export type MutationCreateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
};


export type MutationDeleteAddressArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteAlarmArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteAlarmEventArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBeaconArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBeaconModelArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBeaconParameterArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBeaconValueArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCaseArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCaseItemArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCommentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCustomerArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDetectionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteGroupArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteIntegrationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLocationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLogArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteManufacturerArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNotificationArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePacketArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePersonArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteReportArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteReportResultArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRouteArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTagArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTeleLogArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTeleMonitoringArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUnitArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUnitModelArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUnitParameterArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUnitValueArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUploadFileArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUploadFolderArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserGroupArgs = {
  id: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeleteUsersPermissionsRoleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUsersPermissionsUserArgs = {
  id: Scalars['ID'];
};


export type MutationEmailConfirmationArgs = {
  confirmation: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationLoginArgs = {
  input: UsersPermissionsLoginInput;
};


export type MutationMultipleUploadArgs = {
  field?: InputMaybe<Scalars['String']>;
  files: Array<InputMaybe<Scalars['Upload']>>;
  ref?: InputMaybe<Scalars['String']>;
  refId?: InputMaybe<Scalars['ID']>;
};


export type MutationRegisterArgs = {
  input: UsersPermissionsRegisterInput;
};


export type MutationRemoveFileArgs = {
  id: Scalars['ID'];
};


export type MutationReportResultPdfArgs = {
  id: Scalars['Int'];
};


export type MutationRequestResetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  code: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


export type MutationUnitValuesPdfArgs = {
  accumulated?: InputMaybe<Scalars['Boolean']>;
  graphImage?: InputMaybe<Scalars['String']>;
  parameterId: Scalars['Int'];
  start: Scalars['DateTime'];
  stop: Scalars['DateTime'];
  title?: InputMaybe<Scalars['String']>;
  tzOffset: Scalars['Int'];
  unitId: Scalars['Int'];
};


export type MutationUpdateAddressArgs = {
  data: AddressInput;
  id: Scalars['ID'];
};


export type MutationUpdateAlarmArgs = {
  data: AlarmInput;
  id: Scalars['ID'];
};


export type MutationUpdateAlarmEventArgs = {
  data: AlarmEventInput;
  id: Scalars['ID'];
};


export type MutationUpdateBeaconArgs = {
  data: BeaconInput;
  id: Scalars['ID'];
};


export type MutationUpdateBeaconModelArgs = {
  data: BeaconModelInput;
  id: Scalars['ID'];
};


export type MutationUpdateBeaconParameterArgs = {
  data: BeaconParameterInput;
  id: Scalars['ID'];
};


export type MutationUpdateBeaconValueArgs = {
  data: BeaconValueInput;
  id: Scalars['ID'];
};


export type MutationUpdateCaseArgs = {
  data: CaseInput;
  id: Scalars['ID'];
};


export type MutationUpdateCaseItemArgs = {
  data: CaseItemInput;
  id: Scalars['ID'];
};


export type MutationUpdateCommentArgs = {
  data: CommentInput;
  id: Scalars['ID'];
};


export type MutationUpdateCustomerArgs = {
  data: CustomerInput;
  id: Scalars['ID'];
};


export type MutationUpdateDetectionArgs = {
  data: DetectionInput;
  id: Scalars['ID'];
};


export type MutationUpdateFileInfoArgs = {
  id: Scalars['ID'];
  info?: InputMaybe<FileInfoInput>;
};


export type MutationUpdateGroupArgs = {
  data: GroupInput;
  id: Scalars['ID'];
};


export type MutationUpdateIntegrationArgs = {
  data: IntegrationInput;
  id: Scalars['ID'];
};


export type MutationUpdateLocationArgs = {
  data: LocationInput;
  id: Scalars['ID'];
};


export type MutationUpdateLogArgs = {
  data: LogInput;
  id: Scalars['ID'];
};


export type MutationUpdateManufacturerArgs = {
  data: ManufacturerInput;
  id: Scalars['ID'];
};


export type MutationUpdateNotificationArgs = {
  data: NotificationInput;
  id: Scalars['ID'];
};


export type MutationUpdatePacketArgs = {
  data: PacketInput;
  id: Scalars['ID'];
};


export type MutationUpdatePersonArgs = {
  data: PersonInput;
  id: Scalars['ID'];
};


export type MutationUpdateReportArgs = {
  data: ReportInput;
  id: Scalars['ID'];
};


export type MutationUpdateReportResultArgs = {
  data: ReportResultInput;
  id: Scalars['ID'];
};


export type MutationUpdateRouteArgs = {
  data: RouteInput;
  id: Scalars['ID'];
};


export type MutationUpdateSuccessfulTelemetryExecutionArgs = {
  data: SuccessfulTelemetryExecutionInput;
};


export type MutationUpdateTagArgs = {
  data: TagInput;
  id: Scalars['ID'];
};


export type MutationUpdateTeleLogArgs = {
  data: TeleLogInput;
  id: Scalars['ID'];
};


export type MutationUpdateTeleMonitoringArgs = {
  data: TeleMonitoringInput;
  id: Scalars['ID'];
};


export type MutationUpdateUnitArgs = {
  data: UnitInput;
  id: Scalars['ID'];
};


export type MutationUpdateUnitModelArgs = {
  data: UnitModelInput;
  id: Scalars['ID'];
};


export type MutationUpdateUnitParameterArgs = {
  data: UnitParameterInput;
  id: Scalars['ID'];
};


export type MutationUpdateUnitValueArgs = {
  data: UnitValueInput;
  id: Scalars['ID'];
};


export type MutationUpdateUploadFileArgs = {
  data: UploadFileInput;
  id: Scalars['ID'];
};


export type MutationUpdateUploadFolderArgs = {
  data: UploadFolderInput;
  id: Scalars['ID'];
};


export type MutationUpdateUserGroupArgs = {
  data: UserGroupInput;
  id: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationUpdateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
  id: Scalars['ID'];
};


export type MutationUpdateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
  id: Scalars['ID'];
};


export type MutationUploadArgs = {
  field?: InputMaybe<Scalars['String']>;
  file: Scalars['Upload'];
  info?: InputMaybe<FileInfoInput>;
  ref?: InputMaybe<Scalars['String']>;
  refId?: InputMaybe<Scalars['ID']>;
};

export type Notification = {
  __typename?: 'Notification';
  alarm?: Maybe<AlarmEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  heading?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  receiver_email?: Maybe<Scalars['String']>;
  receiver_number?: Maybe<Scalars['String']>;
  receiver_user?: Maybe<UsersPermissionsUserEntityResponse>;
  type?: Maybe<Enum_Notification_Type>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationEntity = {
  __typename?: 'NotificationEntity';
  attributes?: Maybe<Notification>;
  id?: Maybe<Scalars['ID']>;
};

export type NotificationEntityResponse = {
  __typename?: 'NotificationEntityResponse';
  data?: Maybe<NotificationEntity>;
};

export type NotificationEntityResponseCollection = {
  __typename?: 'NotificationEntityResponseCollection';
  data: Array<NotificationEntity>;
  meta: ResponseCollectionMeta;
};

export type NotificationFiltersInput = {
  alarm?: InputMaybe<AlarmFiltersInput>;
  and?: InputMaybe<Array<InputMaybe<NotificationFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  heading?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  message?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<NotificationFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<NotificationFiltersInput>>>;
  receiver_email?: InputMaybe<StringFilterInput>;
  receiver_number?: InputMaybe<StringFilterInput>;
  receiver_user?: InputMaybe<UsersPermissionsUserFiltersInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type NotificationInput = {
  alarm?: InputMaybe<Scalars['ID']>;
  heading?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  receiver_email?: InputMaybe<Scalars['String']>;
  receiver_number?: InputMaybe<Scalars['String']>;
  receiver_user?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<Enum_Notification_Type>;
};

export type Packet = {
  __typename?: 'Packet';
  createdAt?: Maybe<Scalars['DateTime']>;
  data: Scalars['JSON'];
  unit?: Maybe<UnitEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PacketEntity = {
  __typename?: 'PacketEntity';
  attributes?: Maybe<Packet>;
  id?: Maybe<Scalars['ID']>;
};

export type PacketEntityResponse = {
  __typename?: 'PacketEntityResponse';
  data?: Maybe<PacketEntity>;
};

export type PacketEntityResponseCollection = {
  __typename?: 'PacketEntityResponseCollection';
  data: Array<PacketEntity>;
  meta: ResponseCollectionMeta;
};

export type PacketFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<PacketFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  data?: InputMaybe<JsonFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<PacketFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<PacketFiltersInput>>>;
  unit?: InputMaybe<UnitFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type PacketInput = {
  data?: InputMaybe<Scalars['JSON']>;
  unit?: InputMaybe<Scalars['ID']>;
};

export type Pagination = {
  __typename?: 'Pagination';
  page: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type PaginationArg = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['Int']>;
};

export type Person = {
  __typename?: 'Person';
  beacons?: Maybe<BeaconRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer?: Maybe<CustomerEntityResponse>;
  email?: Maybe<Scalars['String']>;
  first_name: Scalars['String'];
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  units?: Maybe<UnitRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type PersonBeaconsArgs = {
  filters?: InputMaybe<BeaconFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type PersonUnitsArgs = {
  filters?: InputMaybe<UnitFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PersonEntity = {
  __typename?: 'PersonEntity';
  attributes?: Maybe<Person>;
  id?: Maybe<Scalars['ID']>;
};

export type PersonEntityResponse = {
  __typename?: 'PersonEntityResponse';
  data?: Maybe<PersonEntity>;
};

export type PersonEntityResponseCollection = {
  __typename?: 'PersonEntityResponseCollection';
  data: Array<PersonEntity>;
  meta: ResponseCollectionMeta;
};

export type PersonFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<PersonFiltersInput>>>;
  beacons?: InputMaybe<BeaconFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  customer?: InputMaybe<CustomerFiltersInput>;
  email?: InputMaybe<StringFilterInput>;
  first_name?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  last_name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<PersonFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<PersonFiltersInput>>>;
  phone?: InputMaybe<StringFilterInput>;
  units?: InputMaybe<UnitFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type PersonInput = {
  beacons?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  customer?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  units?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type PersonRelationResponseCollection = {
  __typename?: 'PersonRelationResponseCollection';
  data: Array<PersonEntity>;
};

export type Query = {
  __typename?: 'Query';
  address?: Maybe<AddressEntityResponse>;
  addresses?: Maybe<AddressEntityResponseCollection>;
  alarm?: Maybe<AlarmEntityResponse>;
  alarmEvent?: Maybe<AlarmEventEntityResponse>;
  alarmEvents?: Maybe<AlarmEventEntityResponseCollection>;
  alarms?: Maybe<AlarmEntityResponseCollection>;
  beacon?: Maybe<BeaconEntityResponse>;
  beaconModel?: Maybe<BeaconModelEntityResponse>;
  beaconModels?: Maybe<BeaconModelEntityResponseCollection>;
  beaconParameter?: Maybe<BeaconParameterEntityResponse>;
  beaconParameters?: Maybe<BeaconParameterEntityResponseCollection>;
  beaconValue?: Maybe<BeaconValueEntityResponse>;
  beaconValues?: Maybe<BeaconValueEntityResponseCollection>;
  beacons?: Maybe<BeaconEntityResponseCollection>;
  case?: Maybe<CaseEntityResponse>;
  caseItem?: Maybe<CaseItemEntityResponse>;
  caseItems?: Maybe<CaseItemEntityResponseCollection>;
  cases?: Maybe<CaseEntityResponseCollection>;
  comment?: Maybe<CommentEntityResponse>;
  comments?: Maybe<CommentEntityResponseCollection>;
  customer?: Maybe<CustomerEntityResponse>;
  customers?: Maybe<CustomerEntityResponseCollection>;
  detection?: Maybe<DetectionEntityResponse>;
  detections?: Maybe<DetectionEntityResponseCollection>;
  group?: Maybe<GroupEntityResponse>;
  groups?: Maybe<GroupEntityResponseCollection>;
  i18NLocale?: Maybe<I18NLocaleEntityResponse>;
  i18NLocales?: Maybe<I18NLocaleEntityResponseCollection>;
  integration?: Maybe<IntegrationEntityResponse>;
  integrations?: Maybe<IntegrationEntityResponseCollection>;
  location?: Maybe<LocationEntityResponse>;
  locations?: Maybe<LocationEntityResponseCollection>;
  log?: Maybe<LogEntityResponse>;
  logs?: Maybe<LogEntityResponseCollection>;
  manufacturer?: Maybe<ManufacturerEntityResponse>;
  manufacturers?: Maybe<ManufacturerEntityResponseCollection>;
  mapObject?: Maybe<MapObject>;
  mapObjectGroups?: Maybe<MapObjectGroupsResponse>;
  mapObjects?: Maybe<MapObjectsResponse>;
  me?: Maybe<UsersPermissionsMe>;
  notification?: Maybe<NotificationEntityResponse>;
  notifications?: Maybe<NotificationEntityResponseCollection>;
  packet?: Maybe<PacketEntityResponse>;
  packets?: Maybe<PacketEntityResponseCollection>;
  people?: Maybe<PersonEntityResponseCollection>;
  person?: Maybe<PersonEntityResponse>;
  report?: Maybe<ReportEntityResponse>;
  reportResult?: Maybe<ReportResultEntityResponse>;
  reportResults?: Maybe<ReportResultEntityResponseCollection>;
  reports?: Maybe<ReportEntityResponseCollection>;
  route?: Maybe<RouteEntityResponse>;
  routes?: Maybe<RouteEntityResponseCollection>;
  successfulTelemetryExecution?: Maybe<SuccessfulTelemetryExecutionEntityResponse>;
  tag?: Maybe<TagEntityResponse>;
  tags?: Maybe<TagEntityResponseCollection>;
  teleLog?: Maybe<TeleLogEntityResponse>;
  teleLogs?: Maybe<TeleLogEntityResponseCollection>;
  teleMonitoring?: Maybe<TeleMonitoringEntityResponse>;
  teleMonitorings?: Maybe<TeleMonitoringEntityResponseCollection>;
  telenor?: Maybe<TelenorResponse>;
  unit?: Maybe<UnitEntityResponse>;
  unitModel?: Maybe<UnitModelEntityResponse>;
  unitModels?: Maybe<UnitModelEntityResponseCollection>;
  unitParameter?: Maybe<UnitParameterEntityResponse>;
  unitParameters?: Maybe<UnitParameterEntityResponseCollection>;
  unitValue?: Maybe<UnitValueEntityResponse>;
  unitValues?: Maybe<UnitValueEntityResponseCollection>;
  units?: Maybe<UnitEntityResponseCollection>;
  uploadFile?: Maybe<UploadFileEntityResponse>;
  uploadFiles?: Maybe<UploadFileEntityResponseCollection>;
  uploadFolder?: Maybe<UploadFolderEntityResponse>;
  uploadFolders?: Maybe<UploadFolderEntityResponseCollection>;
  userGroup?: Maybe<UserGroupEntityResponse>;
  userGroups?: Maybe<UserGroupEntityResponseCollection>;
  usersPermissionsRole?: Maybe<UsersPermissionsRoleEntityResponse>;
  usersPermissionsRoles?: Maybe<UsersPermissionsRoleEntityResponseCollection>;
  usersPermissionsUser?: Maybe<UsersPermissionsUserEntityResponse>;
  usersPermissionsUsers?: Maybe<UsersPermissionsUserEntityResponseCollection>;
};


export type QueryAddressArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryAddressesArgs = {
  filters?: InputMaybe<AddressFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryAlarmArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryAlarmEventArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryAlarmEventsArgs = {
  filters?: InputMaybe<AlarmEventFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryAlarmsArgs = {
  filters?: InputMaybe<AlarmFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryBeaconArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryBeaconModelArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryBeaconModelsArgs = {
  filters?: InputMaybe<BeaconModelFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryBeaconParameterArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryBeaconParametersArgs = {
  filters?: InputMaybe<BeaconParameterFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryBeaconValueArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryBeaconValuesArgs = {
  filters?: InputMaybe<BeaconValueFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryBeaconsArgs = {
  filters?: InputMaybe<BeaconFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCaseArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCaseItemArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCaseItemsArgs = {
  filters?: InputMaybe<CaseItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCasesArgs = {
  filters?: InputMaybe<CaseFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCommentArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCommentsArgs = {
  filters?: InputMaybe<CommentFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCustomerArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCustomersArgs = {
  filters?: InputMaybe<CustomerFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryDetectionArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryDetectionsArgs = {
  filters?: InputMaybe<DetectionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryGroupArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGroupsArgs = {
  filters?: InputMaybe<GroupFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryI18NLocaleArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryI18NLocalesArgs = {
  filters?: InputMaybe<I18NLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryIntegrationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryIntegrationsArgs = {
  filters?: InputMaybe<IntegrationFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryLocationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryLocationsArgs = {
  filters?: InputMaybe<LocationFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryLogArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryLogsArgs = {
  filters?: InputMaybe<LogFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryManufacturerArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryManufacturersArgs = {
  filters?: InputMaybe<ManufacturerFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryMapObjectArgs = {
  id: Scalars['String'];
};


export type QueryMapObjectGroupsArgs = {
  pagination?: InputMaybe<PaginationArg>;
};


export type QueryMapObjectsArgs = {
  filters?: InputMaybe<MapObjectFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  searchQuery?: InputMaybe<Scalars['String']>;
};


export type QueryNotificationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryNotificationsArgs = {
  filters?: InputMaybe<NotificationFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryPacketArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPacketsArgs = {
  filters?: InputMaybe<PacketFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryPeopleArgs = {
  filters?: InputMaybe<PersonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryPersonArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryReportArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryReportResultArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryReportResultsArgs = {
  filters?: InputMaybe<ReportResultFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryReportsArgs = {
  filters?: InputMaybe<ReportFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryRouteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryRoutesArgs = {
  filters?: InputMaybe<RouteFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryTagArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryTagsArgs = {
  filters?: InputMaybe<TagFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryTeleLogArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryTeleLogsArgs = {
  filters?: InputMaybe<TeleLogFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryTeleMonitoringArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryTeleMonitoringsArgs = {
  filters?: InputMaybe<TeleMonitoringFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryTelenorArgs = {
  imsi: Scalars['String'];
};


export type QueryUnitArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUnitModelArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUnitModelsArgs = {
  filters?: InputMaybe<UnitModelFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUnitParameterArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUnitParametersArgs = {
  filters?: InputMaybe<UnitParameterFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUnitValueArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUnitValuesArgs = {
  filters?: InputMaybe<UnitValueFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUnitsArgs = {
  filters?: InputMaybe<UnitFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUploadFileArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUploadFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUploadFolderArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUploadFoldersArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUserGroupArgs = {
  id?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type QueryUserGroupsArgs = {
  filters?: InputMaybe<UserGroupFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUsersPermissionsRoleArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUsersPermissionsRolesArgs = {
  filters?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUsersPermissionsUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUsersPermissionsUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Report = {
  __typename?: 'Report';
  auto_add_compatible_units?: Maybe<Scalars['Boolean']>;
  configuration?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer?: Maybe<CustomerEntityResponse>;
  days?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['String']>;
  generation_day?: Maybe<Enum_Report_Generation_Day>;
  generation_time?: Maybe<Scalars['Time']>;
  generation_type: Enum_Report_Generation_Type;
  name: Scalars['String'];
  next_allowed_result_generation_time?: Maybe<Scalars['DateTime']>;
  private?: Maybe<Scalars['Boolean']>;
  results?: Maybe<ReportResultRelationResponseCollection>;
  show_zero_data?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['Date']>;
  stop?: Maybe<Scalars['Date']>;
  type: Enum_Report_Type;
  units?: Maybe<UnitRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UsersPermissionsUserEntityResponse>;
};


export type ReportResultsArgs = {
  filters?: InputMaybe<ReportResultFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ReportUnitsArgs = {
  filters?: InputMaybe<UnitFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ReportEntity = {
  __typename?: 'ReportEntity';
  attributes?: Maybe<Report>;
  id?: Maybe<Scalars['ID']>;
};

export type ReportEntityResponse = {
  __typename?: 'ReportEntityResponse';
  data?: Maybe<ReportEntity>;
};

export type ReportEntityResponseCollection = {
  __typename?: 'ReportEntityResponseCollection';
  data: Array<ReportEntity>;
  meta: ResponseCollectionMeta;
};

export type ReportFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ReportFiltersInput>>>;
  auto_add_compatible_units?: InputMaybe<BooleanFilterInput>;
  configuration?: InputMaybe<JsonFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  customer?: InputMaybe<CustomerFiltersInput>;
  days?: InputMaybe<JsonFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  generation_day?: InputMaybe<StringFilterInput>;
  generation_time?: InputMaybe<TimeFilterInput>;
  generation_type?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  next_allowed_result_generation_time?: InputMaybe<DateTimeFilterInput>;
  not?: InputMaybe<ReportFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ReportFiltersInput>>>;
  private?: InputMaybe<BooleanFilterInput>;
  results?: InputMaybe<ReportResultFiltersInput>;
  show_zero_data?: InputMaybe<BooleanFilterInput>;
  start?: InputMaybe<DateFilterInput>;
  stop?: InputMaybe<DateFilterInput>;
  type?: InputMaybe<StringFilterInput>;
  units?: InputMaybe<UnitFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  user?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type ReportInput = {
  auto_add_compatible_units?: InputMaybe<Scalars['Boolean']>;
  configuration?: InputMaybe<Scalars['JSON']>;
  customer?: InputMaybe<Scalars['ID']>;
  days?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  generation_day?: InputMaybe<Enum_Report_Generation_Day>;
  generation_time?: InputMaybe<Scalars['Time']>;
  generation_type?: InputMaybe<Enum_Report_Generation_Type>;
  name?: InputMaybe<Scalars['String']>;
  next_allowed_result_generation_time?: InputMaybe<Scalars['DateTime']>;
  private?: InputMaybe<Scalars['Boolean']>;
  results?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  show_zero_data?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['Date']>;
  stop?: InputMaybe<Scalars['Date']>;
  type?: InputMaybe<Enum_Report_Type>;
  units?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  user?: InputMaybe<Scalars['ID']>;
};

export type ReportRelationResponseCollection = {
  __typename?: 'ReportRelationResponseCollection';
  data: Array<ReportEntity>;
};

export type ReportResult = {
  __typename?: 'ReportResult';
  createdAt?: Maybe<Scalars['DateTime']>;
  data?: Maybe<Scalars['JSON']>;
  input?: Maybe<Scalars['JSON']>;
  outdated?: Maybe<Scalars['DateTime']>;
  report?: Maybe<ReportEntityResponse>;
  start: Scalars['Date'];
  stop: Scalars['Date'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReportResultEntity = {
  __typename?: 'ReportResultEntity';
  attributes?: Maybe<ReportResult>;
  id?: Maybe<Scalars['ID']>;
};

export type ReportResultEntityResponse = {
  __typename?: 'ReportResultEntityResponse';
  data?: Maybe<ReportResultEntity>;
};

export type ReportResultEntityResponseCollection = {
  __typename?: 'ReportResultEntityResponseCollection';
  data: Array<ReportResultEntity>;
  meta: ResponseCollectionMeta;
};

export type ReportResultFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ReportResultFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  data?: InputMaybe<JsonFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  input?: InputMaybe<JsonFilterInput>;
  not?: InputMaybe<ReportResultFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ReportResultFiltersInput>>>;
  outdated?: InputMaybe<DateTimeFilterInput>;
  report?: InputMaybe<ReportFiltersInput>;
  start?: InputMaybe<DateFilterInput>;
  stop?: InputMaybe<DateFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ReportResultInput = {
  data?: InputMaybe<Scalars['JSON']>;
  input?: InputMaybe<Scalars['JSON']>;
  outdated?: InputMaybe<Scalars['DateTime']>;
  report?: InputMaybe<Scalars['ID']>;
  start?: InputMaybe<Scalars['Date']>;
  stop?: InputMaybe<Scalars['Date']>;
};

export type ReportResultRelationResponseCollection = {
  __typename?: 'ReportResultRelationResponseCollection';
  data: Array<ReportResultEntity>;
};

export type ResponseCollectionMeta = {
  __typename?: 'ResponseCollectionMeta';
  pagination: Pagination;
};

export type Route = {
  __typename?: 'Route';
  beacon?: Maybe<BeaconEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  locations?: Maybe<LocationRelationResponseCollection>;
  unit?: Maybe<UnitEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type RouteLocationsArgs = {
  filters?: InputMaybe<LocationFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type RouteEntity = {
  __typename?: 'RouteEntity';
  attributes?: Maybe<Route>;
  id?: Maybe<Scalars['ID']>;
};

export type RouteEntityResponse = {
  __typename?: 'RouteEntityResponse';
  data?: Maybe<RouteEntity>;
};

export type RouteEntityResponseCollection = {
  __typename?: 'RouteEntityResponseCollection';
  data: Array<RouteEntity>;
  meta: ResponseCollectionMeta;
};

export type RouteFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<RouteFiltersInput>>>;
  beacon?: InputMaybe<BeaconFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  locations?: InputMaybe<LocationFiltersInput>;
  not?: InputMaybe<RouteFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<RouteFiltersInput>>>;
  unit?: InputMaybe<UnitFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type RouteInput = {
  beacon?: InputMaybe<Scalars['ID']>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  unit?: InputMaybe<Scalars['ID']>;
};

export type RouteRelationResponseCollection = {
  __typename?: 'RouteRelationResponseCollection';
  data: Array<RouteEntity>;
};

export type StringFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contains?: InputMaybe<Scalars['String']>;
  containsi?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  eqi?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['String']>;
  nei?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<StringFilterInput>;
  notContains?: InputMaybe<Scalars['String']>;
  notContainsi?: InputMaybe<Scalars['String']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type SuccessfulTelemetryExecution = {
  __typename?: 'SuccessfulTelemetryExecution';
  createdAt?: Maybe<Scalars['DateTime']>;
  kinesis_shard_data?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SuccessfulTelemetryExecutionEntity = {
  __typename?: 'SuccessfulTelemetryExecutionEntity';
  attributes?: Maybe<SuccessfulTelemetryExecution>;
  id?: Maybe<Scalars['ID']>;
};

export type SuccessfulTelemetryExecutionEntityResponse = {
  __typename?: 'SuccessfulTelemetryExecutionEntityResponse';
  data?: Maybe<SuccessfulTelemetryExecutionEntity>;
};

export type SuccessfulTelemetryExecutionInput = {
  kinesis_shard_data?: InputMaybe<Scalars['JSON']>;
};

export type Tag = {
  __typename?: 'Tag';
  beacons?: Maybe<BeaconRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer?: Maybe<CustomerEntityResponse>;
  groups?: Maybe<GroupRelationResponseCollection>;
  name: Scalars['String'];
  units?: Maybe<UnitRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type TagBeaconsArgs = {
  filters?: InputMaybe<BeaconFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type TagGroupsArgs = {
  filters?: InputMaybe<GroupFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type TagUnitsArgs = {
  filters?: InputMaybe<UnitFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TagEntity = {
  __typename?: 'TagEntity';
  attributes?: Maybe<Tag>;
  id?: Maybe<Scalars['ID']>;
};

export type TagEntityResponse = {
  __typename?: 'TagEntityResponse';
  data?: Maybe<TagEntity>;
};

export type TagEntityResponseCollection = {
  __typename?: 'TagEntityResponseCollection';
  data: Array<TagEntity>;
  meta: ResponseCollectionMeta;
};

export type TagFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<TagFiltersInput>>>;
  beacons?: InputMaybe<BeaconFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  customer?: InputMaybe<CustomerFiltersInput>;
  groups?: InputMaybe<GroupFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<TagFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<TagFiltersInput>>>;
  units?: InputMaybe<UnitFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type TagInput = {
  beacons?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  customer?: InputMaybe<Scalars['ID']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
  units?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type TagRelationResponseCollection = {
  __typename?: 'TagRelationResponseCollection';
  data: Array<TagEntity>;
};

export type TeleLog = {
  __typename?: 'TeleLog';
  createdAt?: Maybe<Scalars['DateTime']>;
  data?: Maybe<Scalars['JSON']>;
  has_new_data?: Maybe<Scalars['Boolean']>;
  imsi: Scalars['String'];
  tele_monitoring?: Maybe<TeleMonitoringEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TeleLogEntity = {
  __typename?: 'TeleLogEntity';
  attributes?: Maybe<TeleLog>;
  id?: Maybe<Scalars['ID']>;
};

export type TeleLogEntityResponse = {
  __typename?: 'TeleLogEntityResponse';
  data?: Maybe<TeleLogEntity>;
};

export type TeleLogEntityResponseCollection = {
  __typename?: 'TeleLogEntityResponseCollection';
  data: Array<TeleLogEntity>;
  meta: ResponseCollectionMeta;
};

export type TeleLogFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<TeleLogFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  data?: InputMaybe<JsonFilterInput>;
  has_new_data?: InputMaybe<BooleanFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  imsi?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<TeleLogFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<TeleLogFiltersInput>>>;
  tele_monitoring?: InputMaybe<TeleMonitoringFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type TeleLogInput = {
  data?: InputMaybe<Scalars['JSON']>;
  has_new_data?: InputMaybe<Scalars['Boolean']>;
  imsi?: InputMaybe<Scalars['String']>;
  tele_monitoring?: InputMaybe<Scalars['ID']>;
};

export type TeleLogRelationResponseCollection = {
  __typename?: 'TeleLogRelationResponseCollection';
  data: Array<TeleLogEntity>;
};

export type TeleMonitoring = {
  __typename?: 'TeleMonitoring';
  createdAt?: Maybe<Scalars['DateTime']>;
  imsi: Scalars['String'];
  latest_new_data?: Maybe<Scalars['DateTime']>;
  logs?: Maybe<TeleLogRelationResponseCollection>;
  lookup_interval: Scalars['Int'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UsersPermissionsUserEntityResponse>;
};


export type TeleMonitoringLogsArgs = {
  filters?: InputMaybe<TeleLogFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TeleMonitoringEntity = {
  __typename?: 'TeleMonitoringEntity';
  attributes?: Maybe<TeleMonitoring>;
  id?: Maybe<Scalars['ID']>;
};

export type TeleMonitoringEntityResponse = {
  __typename?: 'TeleMonitoringEntityResponse';
  data?: Maybe<TeleMonitoringEntity>;
};

export type TeleMonitoringEntityResponseCollection = {
  __typename?: 'TeleMonitoringEntityResponseCollection';
  data: Array<TeleMonitoringEntity>;
  meta: ResponseCollectionMeta;
};

export type TeleMonitoringFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<TeleMonitoringFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  imsi?: InputMaybe<StringFilterInput>;
  latest_new_data?: InputMaybe<DateTimeFilterInput>;
  logs?: InputMaybe<TeleLogFiltersInput>;
  lookup_interval?: InputMaybe<IntFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<TeleMonitoringFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<TeleMonitoringFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  user?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type TeleMonitoringInput = {
  imsi?: InputMaybe<Scalars['String']>;
  latest_new_data?: InputMaybe<Scalars['DateTime']>;
  logs?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  lookup_interval?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['ID']>;
};

export type TeleMonitoringRelationResponseCollection = {
  __typename?: 'TeleMonitoringRelationResponseCollection';
  data: Array<TeleMonitoringEntity>;
};

export type TelenorResponse = {
  __typename?: 'TelenorResponse';
  id: Scalars['ID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  rawData: Scalars['JSON'];
  timestamp: Scalars['DateTime'];
};

export type TimeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Time']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Time']>>>;
  contains?: InputMaybe<Scalars['Time']>;
  containsi?: InputMaybe<Scalars['Time']>;
  endsWith?: InputMaybe<Scalars['Time']>;
  eq?: InputMaybe<Scalars['Time']>;
  eqi?: InputMaybe<Scalars['Time']>;
  gt?: InputMaybe<Scalars['Time']>;
  gte?: InputMaybe<Scalars['Time']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Time']>>>;
  lt?: InputMaybe<Scalars['Time']>;
  lte?: InputMaybe<Scalars['Time']>;
  ne?: InputMaybe<Scalars['Time']>;
  nei?: InputMaybe<Scalars['Time']>;
  not?: InputMaybe<TimeFilterInput>;
  notContains?: InputMaybe<Scalars['Time']>;
  notContainsi?: InputMaybe<Scalars['Time']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Time']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Time']>>>;
  startsWith?: InputMaybe<Scalars['Time']>;
};

export type Unit = {
  __typename?: 'Unit';
  alarms?: Maybe<AlarmRelationResponseCollection>;
  connected_beacons?: Maybe<BeaconRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer?: Maybe<CustomerEntityResponse>;
  customer_reference_number?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  external_fields?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  group?: Maybe<GroupEntityResponse>;
  hidden?: Maybe<Scalars['Boolean']>;
  image?: Maybe<UploadFileRelationResponseCollection>;
  imei: Scalars['String'];
  imsi?: Maybe<Scalars['String']>;
  indeterminate_minutes?: Maybe<Scalars['Int']>;
  installation_state?: Maybe<Enum_Unit_Installation_State>;
  last_connected?: Maybe<Scalars['DateTime']>;
  latestValues: Array<Maybe<LatestUnitValue>>;
  locations?: Maybe<LocationEntityResponseCollection>;
  name?: Maybe<Scalars['String']>;
  online_minutes?: Maybe<Scalars['Int']>;
  people?: Maybe<PersonRelationResponseCollection>;
  phone_number?: Maybe<Scalars['String']>;
  phone_prefix?: Maybe<Scalars['String']>;
  precision_radius?: Maybe<Scalars['Int']>;
  primary_image?: Maybe<Scalars['Int']>;
  registration_number?: Maybe<Scalars['String']>;
  routes?: Maybe<RouteRelationResponseCollection>;
  send_interval?: Maybe<Scalars['Int']>;
  serial_number?: Maybe<Scalars['String']>;
  sim_number?: Maybe<Scalars['String']>;
  tags?: Maybe<TagRelationResponseCollection>;
  unit_model?: Maybe<UnitModelEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  values?: Maybe<UnitValueEntityResponseCollection>;
  vin?: Maybe<Scalars['String']>;
  visible_parameters?: Maybe<UnitParameterRelationResponseCollection>;
  week_report_setup?: Maybe<Scalars['String']>;
};


export type UnitAlarmsArgs = {
  filters?: InputMaybe<AlarmFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UnitConnected_BeaconsArgs = {
  filters?: InputMaybe<BeaconFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UnitImageArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UnitLocationsArgs = {
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UnitPeopleArgs = {
  filters?: InputMaybe<PersonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UnitRoutesArgs = {
  filters?: InputMaybe<RouteFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UnitTagsArgs = {
  filters?: InputMaybe<TagFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UnitValuesArgs = {
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UnitVisible_ParametersArgs = {
  filters?: InputMaybe<UnitParameterFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UnitEntity = {
  __typename?: 'UnitEntity';
  attributes?: Maybe<Unit>;
  id?: Maybe<Scalars['ID']>;
};

export type UnitEntityResponse = {
  __typename?: 'UnitEntityResponse';
  data?: Maybe<UnitEntity>;
};

export type UnitEntityResponseCollection = {
  __typename?: 'UnitEntityResponseCollection';
  data: Array<UnitEntity>;
  meta: ResponseCollectionMeta;
};

export type UnitFiltersInput = {
  alarms?: InputMaybe<AlarmFiltersInput>;
  and?: InputMaybe<Array<InputMaybe<UnitFiltersInput>>>;
  connected_beacons?: InputMaybe<BeaconFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  customer?: InputMaybe<CustomerFiltersInput>;
  customer_reference_number?: InputMaybe<StringFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  external_fields?: InputMaybe<StringFilterInput>;
  external_id?: InputMaybe<StringFilterInput>;
  group?: InputMaybe<GroupFiltersInput>;
  hidden?: InputMaybe<BooleanFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  imei?: InputMaybe<StringFilterInput>;
  imsi?: InputMaybe<StringFilterInput>;
  indeterminate_minutes?: InputMaybe<IntFilterInput>;
  installation_state?: InputMaybe<StringFilterInput>;
  last_connected?: InputMaybe<DateTimeFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UnitFiltersInput>;
  online_minutes?: InputMaybe<IntFilterInput>;
  or?: InputMaybe<Array<InputMaybe<UnitFiltersInput>>>;
  people?: InputMaybe<PersonFiltersInput>;
  phone_number?: InputMaybe<StringFilterInput>;
  phone_prefix?: InputMaybe<StringFilterInput>;
  precision_radius?: InputMaybe<IntFilterInput>;
  primary_image?: InputMaybe<IntFilterInput>;
  registration_number?: InputMaybe<StringFilterInput>;
  routes?: InputMaybe<RouteFiltersInput>;
  send_interval?: InputMaybe<IntFilterInput>;
  serial_number?: InputMaybe<StringFilterInput>;
  sim_number?: InputMaybe<StringFilterInput>;
  tags?: InputMaybe<TagFiltersInput>;
  unit_model?: InputMaybe<UnitModelFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  vin?: InputMaybe<StringFilterInput>;
  visible_parameters?: InputMaybe<UnitParameterFiltersInput>;
  week_report_setup?: InputMaybe<StringFilterInput>;
};

export type UnitInput = {
  alarms?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  connected_beacons?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  customer?: InputMaybe<Scalars['ID']>;
  customer_reference_number?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  external_fields?: InputMaybe<Scalars['String']>;
  external_id?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<Scalars['ID']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  imei?: InputMaybe<Scalars['String']>;
  imsi?: InputMaybe<Scalars['String']>;
  indeterminate_minutes?: InputMaybe<Scalars['Int']>;
  installation_state?: InputMaybe<Enum_Unit_Installation_State>;
  last_connected?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  online_minutes?: InputMaybe<Scalars['Int']>;
  people?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  phone_number?: InputMaybe<Scalars['String']>;
  phone_prefix?: InputMaybe<Scalars['String']>;
  precision_radius?: InputMaybe<Scalars['Int']>;
  primary_image?: InputMaybe<Scalars['Int']>;
  registration_number?: InputMaybe<Scalars['String']>;
  routes?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  send_interval?: InputMaybe<Scalars['Int']>;
  serial_number?: InputMaybe<Scalars['String']>;
  sim_number?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  unit_model?: InputMaybe<Scalars['ID']>;
  vin?: InputMaybe<Scalars['String']>;
  visible_parameters?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  week_report_setup?: InputMaybe<Scalars['String']>;
};

export type UnitModel = {
  __typename?: 'UnitModel';
  createdAt?: Maybe<Scalars['DateTime']>;
  default_visible_parameters?: Maybe<UnitParameterRelationResponseCollection>;
  manufacturer?: Maybe<ManufacturerEntityResponse>;
  name: Scalars['String'];
  parser: Enum_Unitmodel_Parser;
  precision_radius?: Maybe<Scalars['Int']>;
  report_types?: Maybe<Scalars['JSON']>;
  setup_command?: Maybe<Scalars['String']>;
  unit_parameters?: Maybe<UnitParameterRelationResponseCollection>;
  units?: Maybe<UnitRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type UnitModelDefault_Visible_ParametersArgs = {
  filters?: InputMaybe<UnitParameterFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UnitModelUnit_ParametersArgs = {
  filters?: InputMaybe<UnitParameterFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UnitModelUnitsArgs = {
  filters?: InputMaybe<UnitFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UnitModelEntity = {
  __typename?: 'UnitModelEntity';
  attributes?: Maybe<UnitModel>;
  id?: Maybe<Scalars['ID']>;
};

export type UnitModelEntityResponse = {
  __typename?: 'UnitModelEntityResponse';
  data?: Maybe<UnitModelEntity>;
};

export type UnitModelEntityResponseCollection = {
  __typename?: 'UnitModelEntityResponseCollection';
  data: Array<UnitModelEntity>;
  meta: ResponseCollectionMeta;
};

export type UnitModelFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UnitModelFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  default_visible_parameters?: InputMaybe<UnitParameterFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  manufacturer?: InputMaybe<ManufacturerFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UnitModelFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UnitModelFiltersInput>>>;
  parser?: InputMaybe<StringFilterInput>;
  precision_radius?: InputMaybe<IntFilterInput>;
  report_types?: InputMaybe<JsonFilterInput>;
  setup_command?: InputMaybe<StringFilterInput>;
  unit_parameters?: InputMaybe<UnitParameterFiltersInput>;
  units?: InputMaybe<UnitFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UnitModelInput = {
  default_visible_parameters?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  manufacturer?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  parser?: InputMaybe<Enum_Unitmodel_Parser>;
  precision_radius?: InputMaybe<Scalars['Int']>;
  report_types?: InputMaybe<Scalars['JSON']>;
  setup_command?: InputMaybe<Scalars['String']>;
  unit_parameters?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  units?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type UnitModelRelationResponseCollection = {
  __typename?: 'UnitModelRelationResponseCollection';
  data: Array<UnitModelEntity>;
};

export type UnitParameter = {
  __typename?: 'UnitParameter';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  max_value?: Maybe<Scalars['String']>;
  min_value?: Maybe<Scalars['String']>;
  multiplier?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parameter_id: Scalars['Int'];
  type?: Maybe<Enum_Unitparameter_Type>;
  unit?: Maybe<Scalars['String']>;
  unit_models?: Maybe<UnitModelRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type UnitParameterUnit_ModelsArgs = {
  filters?: InputMaybe<UnitModelFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UnitParameterEntity = {
  __typename?: 'UnitParameterEntity';
  attributes?: Maybe<UnitParameter>;
  id?: Maybe<Scalars['ID']>;
};

export type UnitParameterEntityResponse = {
  __typename?: 'UnitParameterEntityResponse';
  data?: Maybe<UnitParameterEntity>;
};

export type UnitParameterEntityResponseCollection = {
  __typename?: 'UnitParameterEntityResponseCollection';
  data: Array<UnitParameterEntity>;
  meta: ResponseCollectionMeta;
};

export type UnitParameterFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UnitParameterFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  max_value?: InputMaybe<StringFilterInput>;
  min_value?: InputMaybe<StringFilterInput>;
  multiplier?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UnitParameterFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UnitParameterFiltersInput>>>;
  parameter_id?: InputMaybe<IntFilterInput>;
  type?: InputMaybe<StringFilterInput>;
  unit?: InputMaybe<StringFilterInput>;
  unit_models?: InputMaybe<UnitModelFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UnitParameterInput = {
  description?: InputMaybe<Scalars['String']>;
  max_value?: InputMaybe<Scalars['String']>;
  min_value?: InputMaybe<Scalars['String']>;
  multiplier?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parameter_id?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Enum_Unitparameter_Type>;
  unit?: InputMaybe<Scalars['String']>;
  unit_models?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type UnitParameterRelationResponseCollection = {
  __typename?: 'UnitParameterRelationResponseCollection';
  data: Array<UnitParameterEntity>;
};

export type UnitRelationResponseCollection = {
  __typename?: 'UnitRelationResponseCollection';
  data: Array<UnitEntity>;
};

export type UnitValue = {
  __typename?: 'UnitValue';
  createdAt?: Maybe<Scalars['DateTime']>;
  parameter?: Maybe<UnitParameterEntityResponse>;
  timestamp: Scalars['DateTime'];
  unit_id: Scalars['Int'];
  unit_parameter_id: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  value: Scalars['String'];
};

export type UnitValueEntity = {
  __typename?: 'UnitValueEntity';
  attributes?: Maybe<UnitValue>;
  id?: Maybe<Scalars['ID']>;
};

export type UnitValueEntityResponse = {
  __typename?: 'UnitValueEntityResponse';
  data?: Maybe<UnitValueEntity>;
};

export type UnitValueEntityResponseCollection = {
  __typename?: 'UnitValueEntityResponseCollection';
  data: Array<UnitValueEntity>;
  meta: ResponseCollectionMeta;
};

export type UnitValueFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UnitValueFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UnitValueFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UnitValueFiltersInput>>>;
  timestamp?: InputMaybe<DateTimeFilterInput>;
  unit_id?: InputMaybe<IntFilterInput>;
  unit_parameter_id?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  value?: InputMaybe<StringFilterInput>;
};

export type UnitValueInput = {
  timestamp?: InputMaybe<Scalars['DateTime']>;
  unit_id?: InputMaybe<Scalars['Int']>;
  unit_parameter_id?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

export type UploadFile = {
  __typename?: 'UploadFile';
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  ext?: Maybe<Scalars['String']>;
  formats?: Maybe<Scalars['JSON']>;
  hash: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  mime: Scalars['String'];
  name: Scalars['String'];
  previewUrl?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  provider_metadata?: Maybe<Scalars['JSON']>;
  related?: Maybe<Array<Maybe<GenericMorph>>>;
  size: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
};

export type UploadFileEntity = {
  __typename?: 'UploadFileEntity';
  attributes?: Maybe<UploadFile>;
  id?: Maybe<Scalars['ID']>;
};

export type UploadFileEntityResponse = {
  __typename?: 'UploadFileEntityResponse';
  data?: Maybe<UploadFileEntity>;
};

export type UploadFileEntityResponseCollection = {
  __typename?: 'UploadFileEntityResponseCollection';
  data: Array<UploadFileEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFileFiltersInput = {
  alternativeText?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  caption?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  ext?: InputMaybe<StringFilterInput>;
  folder?: InputMaybe<UploadFolderFiltersInput>;
  folderPath?: InputMaybe<StringFilterInput>;
  formats?: InputMaybe<JsonFilterInput>;
  hash?: InputMaybe<StringFilterInput>;
  height?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  mime?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFileFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  previewUrl?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  provider_metadata?: InputMaybe<JsonFilterInput>;
  size?: InputMaybe<FloatFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  url?: InputMaybe<StringFilterInput>;
  width?: InputMaybe<IntFilterInput>;
};

export type UploadFileInput = {
  alternativeText?: InputMaybe<Scalars['String']>;
  caption?: InputMaybe<Scalars['String']>;
  ext?: InputMaybe<Scalars['String']>;
  folder?: InputMaybe<Scalars['ID']>;
  folderPath?: InputMaybe<Scalars['String']>;
  formats?: InputMaybe<Scalars['JSON']>;
  hash?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  mime?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  previewUrl?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  provider_metadata?: InputMaybe<Scalars['JSON']>;
  size?: InputMaybe<Scalars['Float']>;
  url?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type UploadFileRelationResponseCollection = {
  __typename?: 'UploadFileRelationResponseCollection';
  data: Array<UploadFileEntity>;
};

export type UploadFolder = {
  __typename?: 'UploadFolder';
  children?: Maybe<UploadFolderRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  files?: Maybe<UploadFileRelationResponseCollection>;
  name: Scalars['String'];
  parent?: Maybe<UploadFolderEntityResponse>;
  path: Scalars['String'];
  pathId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type UploadFolderChildrenArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UploadFolderFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UploadFolderEntity = {
  __typename?: 'UploadFolderEntity';
  attributes?: Maybe<UploadFolder>;
  id?: Maybe<Scalars['ID']>;
};

export type UploadFolderEntityResponse = {
  __typename?: 'UploadFolderEntityResponse';
  data?: Maybe<UploadFolderEntity>;
};

export type UploadFolderEntityResponseCollection = {
  __typename?: 'UploadFolderEntityResponseCollection';
  data: Array<UploadFolderEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFolderFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  children?: InputMaybe<UploadFolderFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  files?: InputMaybe<UploadFileFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFolderFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  parent?: InputMaybe<UploadFolderFiltersInput>;
  path?: InputMaybe<StringFilterInput>;
  pathId?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UploadFolderInput = {
  children?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  files?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<Scalars['ID']>;
  path?: InputMaybe<Scalars['String']>;
  pathId?: InputMaybe<Scalars['Int']>;
};

export type UploadFolderRelationResponseCollection = {
  __typename?: 'UploadFolderRelationResponseCollection';
  data: Array<UploadFolderEntity>;
};

export type UserGroup = {
  __typename?: 'UserGroup';
  createdAt?: Maybe<Scalars['DateTime']>;
  customer?: Maybe<CustomerEntityResponse>;
  locale?: Maybe<Scalars['String']>;
  localizations?: Maybe<UserGroupRelationResponseCollection>;
  modules?: Maybe<Array<Maybe<ComponentAccessModule>>>;
  name: Scalars['String'];
  permissions?: Maybe<Array<Maybe<ComponentAccessPermission>>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
};


export type UserGroupLocalizationsArgs = {
  filters?: InputMaybe<UserGroupFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UserGroupModulesArgs = {
  filters?: InputMaybe<ComponentAccessModuleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UserGroupPermissionsArgs = {
  filters?: InputMaybe<ComponentAccessPermissionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UserGroupUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UserGroupEntity = {
  __typename?: 'UserGroupEntity';
  attributes?: Maybe<UserGroup>;
  id?: Maybe<Scalars['ID']>;
};

export type UserGroupEntityResponse = {
  __typename?: 'UserGroupEntityResponse';
  data?: Maybe<UserGroupEntity>;
};

export type UserGroupEntityResponseCollection = {
  __typename?: 'UserGroupEntityResponseCollection';
  data: Array<UserGroupEntity>;
  meta: ResponseCollectionMeta;
};

export type UserGroupFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UserGroupFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  customer?: InputMaybe<CustomerFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<UserGroupFiltersInput>;
  modules?: InputMaybe<ComponentAccessModuleFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UserGroupFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UserGroupFiltersInput>>>;
  permissions?: InputMaybe<ComponentAccessPermissionFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  users?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type UserGroupInput = {
  customer?: InputMaybe<Scalars['ID']>;
  modules?: InputMaybe<Array<InputMaybe<ComponentAccessModuleInput>>>;
  name?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<InputMaybe<ComponentAccessPermissionInput>>>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type UserGroupRelationResponseCollection = {
  __typename?: 'UserGroupRelationResponseCollection';
  data: Array<UserGroupEntity>;
};

export type UsersPermissionsCreateRolePayload = {
  __typename?: 'UsersPermissionsCreateRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsDeleteRolePayload = {
  __typename?: 'UsersPermissionsDeleteRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsLoginInput = {
  identifier: Scalars['String'];
  password: Scalars['String'];
  provider?: Scalars['String'];
};

export type UsersPermissionsLoginPayload = {
  __typename?: 'UsersPermissionsLoginPayload';
  jwt?: Maybe<Scalars['String']>;
  user: UsersPermissionsMe;
};

export type UsersPermissionsMe = {
  __typename?: 'UsersPermissionsMe';
  blocked?: Maybe<Scalars['Boolean']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  role?: Maybe<UsersPermissionsMeRole>;
  username: Scalars['String'];
};

export type UsersPermissionsMeRole = {
  __typename?: 'UsersPermissionsMeRole';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type UsersPermissionsPasswordPayload = {
  __typename?: 'UsersPermissionsPasswordPayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsPermission = {
  __typename?: 'UsersPermissionsPermission';
  action: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UsersPermissionsPermissionEntity = {
  __typename?: 'UsersPermissionsPermissionEntity';
  attributes?: Maybe<UsersPermissionsPermission>;
  id?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsPermissionFiltersInput = {
  action?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UsersPermissionsPermissionRelationResponseCollection = {
  __typename?: 'UsersPermissionsPermissionRelationResponseCollection';
  data: Array<UsersPermissionsPermissionEntity>;
};

export type UsersPermissionsRegisterInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export type UsersPermissionsRole = {
  __typename?: 'UsersPermissionsRole';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  permissions?: Maybe<UsersPermissionsPermissionRelationResponseCollection>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
};


export type UsersPermissionsRolePermissionsArgs = {
  filters?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UsersPermissionsRoleUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UsersPermissionsRoleEntity = {
  __typename?: 'UsersPermissionsRoleEntity';
  attributes?: Maybe<UsersPermissionsRole>;
  id?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsRoleEntityResponse = {
  __typename?: 'UsersPermissionsRoleEntityResponse';
  data?: Maybe<UsersPermissionsRoleEntity>;
};

export type UsersPermissionsRoleEntityResponseCollection = {
  __typename?: 'UsersPermissionsRoleEntityResponseCollection';
  data: Array<UsersPermissionsRoleEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsRoleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  permissions?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  users?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type UsersPermissionsRoleInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  type?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type UsersPermissionsUpdateRolePayload = {
  __typename?: 'UsersPermissionsUpdateRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsUser = {
  __typename?: 'UsersPermissionsUser';
  admin?: Maybe<Scalars['Boolean']>;
  alarms?: Maybe<AlarmRelationResponseCollection>;
  avatar?: Maybe<UploadFileRelationResponseCollection>;
  blocked?: Maybe<Scalars['Boolean']>;
  case_notification_subscriptions?: Maybe<CaseRelationResponseCollection>;
  collapsed_sidebar?: Maybe<Scalars['Boolean']>;
  comments?: Maybe<CommentRelationResponseCollection>;
  confirmed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer?: Maybe<CustomerEntityResponse>;
  email: Scalars['String'];
  first_name: Scalars['String'];
  integrations?: Maybe<IntegrationRelationResponseCollection>;
  last_name?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phone_prefix?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  reports?: Maybe<ReportRelationResponseCollection>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  tele_monitorings?: Maybe<TeleMonitoringRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user_group?: Maybe<UserGroupEntityResponse>;
  username: Scalars['String'];
};


export type UsersPermissionsUserAlarmsArgs = {
  filters?: InputMaybe<AlarmFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UsersPermissionsUserAvatarArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UsersPermissionsUserCase_Notification_SubscriptionsArgs = {
  filters?: InputMaybe<CaseFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UsersPermissionsUserCommentsArgs = {
  filters?: InputMaybe<CommentFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UsersPermissionsUserIntegrationsArgs = {
  filters?: InputMaybe<IntegrationFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UsersPermissionsUserReportsArgs = {
  filters?: InputMaybe<ReportFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UsersPermissionsUserTele_MonitoringsArgs = {
  filters?: InputMaybe<TeleMonitoringFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UsersPermissionsUserEntity = {
  __typename?: 'UsersPermissionsUserEntity';
  attributes?: Maybe<UsersPermissionsUser>;
  id?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsUserEntityResponse = {
  __typename?: 'UsersPermissionsUserEntityResponse';
  data?: Maybe<UsersPermissionsUserEntity>;
};

export type UsersPermissionsUserEntityResponseCollection = {
  __typename?: 'UsersPermissionsUserEntityResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsUserFiltersInput = {
  activation_token?: InputMaybe<StringFilterInput>;
  admin?: InputMaybe<BooleanFilterInput>;
  alarms?: InputMaybe<AlarmFiltersInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  blocked?: InputMaybe<BooleanFilterInput>;
  case_notification_subscriptions?: InputMaybe<CaseFiltersInput>;
  collapsed_sidebar?: InputMaybe<BooleanFilterInput>;
  comments?: InputMaybe<CommentFiltersInput>;
  confirmationToken?: InputMaybe<StringFilterInput>;
  confirmed?: InputMaybe<BooleanFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  customer?: InputMaybe<CustomerFiltersInput>;
  email?: InputMaybe<StringFilterInput>;
  first_name?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  integrations?: InputMaybe<IntegrationFiltersInput>;
  last_name?: InputMaybe<StringFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UsersPermissionsUserFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  password?: InputMaybe<StringFilterInput>;
  phone?: InputMaybe<StringFilterInput>;
  phone_prefix?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  reports?: InputMaybe<ReportFiltersInput>;
  resetPasswordToken?: InputMaybe<StringFilterInput>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  tele_monitorings?: InputMaybe<TeleMonitoringFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  user_group?: InputMaybe<UserGroupFiltersInput>;
  username?: InputMaybe<StringFilterInput>;
};

export type UsersPermissionsUserInput = {
  activation_token?: InputMaybe<Scalars['String']>;
  admin?: InputMaybe<Scalars['Boolean']>;
  alarms?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  avatar?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  blocked?: InputMaybe<Scalars['Boolean']>;
  case_notification_subscriptions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  collapsed_sidebar?: InputMaybe<Scalars['Boolean']>;
  comments?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  confirmationToken?: InputMaybe<Scalars['String']>;
  confirmed?: InputMaybe<Scalars['Boolean']>;
  customer?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  integrations?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  last_name?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phone_prefix?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  reports?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  resetPasswordToken?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['ID']>;
  tele_monitorings?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  user_group?: InputMaybe<Scalars['ID']>;
  username?: InputMaybe<Scalars['String']>;
};

export type UsersPermissionsUserRelationResponseCollection = {
  __typename?: 'UsersPermissionsUserRelationResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
};
